export const IS_PRODUCTION = process.env.VUE_APP_PRODUCTION === 'production';

export const HTTP_HEADER_SKIP_ERROR = 'UT-Skip-Error';
export const HTTP_STATUS = {
  badRequest: 400,
  unauthrized: 401,
  unprocessableEntity: 422
};
export const RATE_INCREASE = 0.05;
export const AUTH_COOKIE = {
  name: 'cms_auth_token',
  expires: (1 / 1440) * process.env.VUE_APP_LOGIN_TIME || 1 / 12,
  path: '/'
};
export const STORAGE_ITEM = {
  time: 'keyExpireDate'
};

export const LANG_COOKIE = {
  name: 'cms_lang',
  path: '/'
};

export const SOCK_COOKIE = {
  name: 'cms_sock_token',
  path: '/'
};
export const SITE_INFO = {
  name: 'cms_site_info',
  path: '/'
};

export const DOCUMENT_TITLE_PREFIX = 'OMS';

export const GAME_CODE_NAME = {
  kp: '카지노게임',
  kps: '슬롯게임'
};
export const MINIGAME_GROUP_OPTIONS = {
  powerball: ['noe', 'nudov', 'nsec1', 'nsec2', 'poe', 'pudov', 'power', 'noeudov', 'nosec1', 'nosec2', 'poeudov1', 'poeudov2', 'noeudovpoe'],
  ladder: ['slr', 'l34', 'noe', 'sl']
  // powerball: {
  //   n: ['narea', 'noe', 'nuo'],
  //   nc: ['ncarea', 'ncoe'],
  //   p: ['parea', 'pnum', 'puo', 'poe'],
  //   pc: ['pcoeuo']
  // }
};
export const MINIGAME_GROUP_INFO = {
  coin3: 'powerball',
  coin5: 'powerball',
  coinsa3: 'ladder',
  coinsa5: 'ladder',
  dhkeno: 'powerball',
  dhkenosa: 'ladder',
  eos3: 'powerball',
  eos5: 'powerball',
  epw: 'powerball',
  meta3: 'powerball',
  meta5: 'powerball',
  metasa3: 'ladder',
  metasa5: 'ladder',
  npw3: 'powerball',
  npw5: 'powerball',
  pbg: 'powerball',
  dhball: 'powerball',
  pwball: 'powerball',
  pwsa: 'ladder',
  evopb1: 'powerball',
  evopb2: 'powerball',
  evopb3: 'powerball',
  evopb4: 'powerball',
  evopb5: 'powerball'
};
// export const MINIGAME_OPTION_GROUP_INFO = {
//   powerball: {
//     n: ['narea', 'noe', 'nuo'],
//     nc: ['ncarea', 'ncoe'],
//     p: ['parea', 'pnum', 'puo', 'poe'],
//     pc: ['pcoeuo']
//   }
// }
export const GAME_INFO_LIST = {
  casino: {
    1: 'evol',
    10: 'cn_pp',
    12: 'playtech',
    13: 'hotel',
    2: 'big',
    5: 'aisa',
    6: 'dream',
    9: 'sexy'
  },
  slot: {
    200: 'slot_pp',
    201: 'slot_habanero',
    202: 'slot_elysium',
    204: 'slot_quick',
    205: 'slot_spade',
    207: 'slot_ray',
    208: 'slot_world',
    209: 'slot_micro',
    212: 'slot_yl',
    213: 'slot_evo',
    214: 'slot_netant',
    215: 'slot_dragon',
    216: 'slot_gg',
    217: 'slot_boongo',
    218: 'slot_playson',
    219: 'slot_playtech',
    220: 'slot_cq9',
    221: 'slot_sky'
  },
  // 야구.배구,농구, 아이스하키,
  sport: {
    sp: 'sp',
    events: [
      {
        name: 'soccer',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'draw', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          },
          {
            name: 'special',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'draw', 'away']
              }
            ]
          }
        ]
      },
      {
        name: 'basketball',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          },
          {
            name: 'special',
            gameType: [
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      },
      {
        name: 'baseball',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          },
          {
            name: 'special',
            gameType: [
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      },
      {
        name: 'volleyball',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          },
          {
            name: 'special',
            gameType: [
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      },
      {
        name: 'handball',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'draw', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      },
      {
        name: 'boxing',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              }
            ]
          }
        ]
      },
      {
        name: 'tennis',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      },
      {
        name: 'tabletennis',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              }
            ]
          }
        ]
      },
      {
        name: 'icehockey',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'draw', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          },
          {
            name: 'special',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'draw', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      },
      {
        name: 'americanfootball',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      },
      {
        name: 'esports',
        eventType: [
          {
            name: 'cross',
            gameType: [
              {
                name: 'winlose',
                betTypes: ['home', 'away']
              },
              {
                name: 'handicap',
                betTypes: ['home', 'away']
              },
              {
                name: 'underover',
                betTypes: ['over', 'under']
              }
            ]
          }
        ]
      }
    ],
    gameType: {
      winlose: ['home', 'draw', 'away'],
      underover: ['under', 'over'],
      handicap: ['home', 'away']
    },
    eventType: ['cross', 'special']
  },
  minigame: {
    mg: 'mg',
    kino: 'kino',
    powerball: 'powerball'
  },
  fishing: {
    1000: 'fishing'
  },
  kiron: {
    102: 'kiron'
  },
  esport: {
    101: 'esport'
  }
};

export const GAME_LOBBY_CODE = {
  slot: '0',
  fishing: '1000'
};

export const PARTNER_TYPE = {
  senior: '시니어',
  master: '마스터',
  agency: '에이전시'
};

export const RATE_MODEL = {
  gameType: '',
  pointRate: '0.00',
  useYn: 'Y',
  betYn: 'Y'
};

export const SOCKET_SUBSCRIBE_DESTINATION = '/topic/';

export const CMS_USER_LIST_SELECT_OPTION = 'CMS_USER_LIST_SELECT_OPTION';

export const PARTNER_LEVEL = {
  PTN_1: '총본사',
  PTN_2: '대본사',
  PTN_3: '부본사',
  PTN_4: '총판',
  PTN_5: '매장',
  NORMAL: '회원'
};

export const insureCodeMap = {
  comp1: {
    PWB: '동행 파워볼',
    PWB_NB_MIX: '동행 파워볼 일반볼 조합',
    PWB_PB_MIX: '동행 파워볼 파워볼 조합',
    PWB_OE_MIX: '동행 파워볼 파워볼홀짝 조합',
    PWB_NP_MIX: '동행 파워볼 3조합',
    PSA: '동행 파워사다리',
    PSA_MIX: '동행 파워사다리 조합',
    DSCP3: '코인 파워볼 3분',
    DSCP3_NB_MIX: '코인 파워볼 3분 일반볼 조합',
    DSCP3_PB_MIX: '코인 파워볼 3분 파워볼 조합',
    DSCP3_OE_MIX: '코인 파워볼 3분 파워볼홀짝 조합',
    DSCP5: '코인 파워볼 5분',
    DSCP5_NB_MIX: '코인 파워볼 5분 일반볼 조합',
    DSCP5_PB_MIX: '코인 파워볼 5분 파워볼 조합',
    DSCP5_OE_MIX: '코인 파워볼 5분 파워볼홀짝 조합',
    EOS3: 'EOS 파워볼 3분',
    EOS3_NB_MIX: 'EOS 파워볼 3분 일반볼 조합',
    EOS3_PB_MIX: 'EOS 파워볼 3분 파워볼 조합',
    EOS3_OE_MIX: 'EOS 파워볼 3분 파워볼홀짝 조합',
    EOS5: 'EOS 파워볼 5분',
    EOS5_NB_MIX: 'EOS 파워볼 5분 일반볼 조합',
    EOS5_PB_MIX: 'EOS 파워볼 5분 파워볼 조합',
    EOS5_OE_MIX: 'EOS 파워볼 5분 파워볼홀짝 조합',
    SPKINO: '스피드키노 파워볼',
    SPKINO_NB_MIX: '스피드키노 일반볼 조합',
    SPKINO_PB_MIX: '스피드키노 파워볼 조합',
    SPKINO_OE_MIX: '스피드키노 파워볼홀짝조합',
    MEPB3: '메타볼 3분',
    MEPB3_NB_MIX: '메타볼 3분 일반볼 조합',
    MEPB3_PB_MIX: '메타볼 3분 파워볼 조합',
    MEPB3_OE_MIX: '메타볼 3분 파워볼홀짝 조합',
    MEPB5: '메타볼 5분',
    MEPB5_NB_MIX: '메타볼 5분 일반볼 조합',
    MEPB5_PB_MIX: '메타볼 5분 파워볼 조합',
    MEPB5_OE_MIX: '메타볼 5분 파워볼홀짝 조합',
    DSCL3: '코인 사다리 3분',
    DSCL3_MIX: '코인 사다리 3분 조합',
    DSCL5: '코인 사다리 5분',
    DSCL5_MIX: '코인 사다리 5분 조합',
    KENOL: '키노 사다리',
    KENOL_MIX: '키노 사다리 조합',
    MEPL3: '메타 사다리 3분',
    MEPL3_MIX: '메타 사다리 3분 조합',
    MEPL5: '메타 사다리 5분',
    MEPL5_MIX: '메타 사다리 5분 조합',
    PBG: 'PBG 파워볼',
    PBG_NB_MIX: 'PBG 일반볼 조합',
    PBG_PB_MIX: 'PBG 파워볼 조합',
    PBG_OE_MIX: 'PBG 파워볼홀짝 조합',
    PBG_NP_MIX: 'PBG 3 조합',
    PBG_PNUM: 'PBG 파워볼 숫자',
    NPWB: '나눔 파워볼 3분',
    NPWB_NB_MIX: '나눔 파워볼 3분 일반볼 조합',
    NPWB_PB_MIX: '나눔 파워볼 3분 파워볼 조합',
    NPWB_OE_MIX: '나눔 파워볼 3분 파워볼홀짝 조합',
    EVOPB: 'EVO 파워볼 1 턴',
    EVOPB_NB_MIX: 'EVO 파워볼 1 턴 일반볼 조합',
    EVOPB_PB_MIX: 'EVO 파워볼 1 턴 파워볼 조합',
    EVOPB_OE_MIX: 'EVO 파워볼 1 턴 파워볼홀짝 조합',
    EVOPB2: 'EVO 파워볼 2 턴',
    EVOPB2_NB_MIX: 'EVO 파워볼 2 턴 일반볼 조합',
    EVOPB2_PB_MIX: 'EVO 파워볼 2 턴 파워볼 조합',
    EVOPB2_OE_MIX: 'EVO 파워볼 2 턴 파워볼홀짝 조합',
    EVOPB3: 'EVO 파워볼 3 턴',
    EVOPB3_NB_MIX: 'EVO 파워볼 3 턴 일반볼 조합',
    EVOPB3_PB_MIX: 'EVO 파워볼 3 턴 파워볼 조합',
    EVOPB3_OE_MIX: 'EVO 파워볼 3 턴 파워볼홀짝 조합',
    NBALL3: 'N 파워볼 3분',
    NBALL3_NB_MIX: 'N 파워볼 3분 일반볼 조합',
    NBALL3_PB_MIX: 'N 파워볼 3분 파워볼 조합',
    NBALL3_OE_MIX: 'N 파워볼 3분 파워볼홀짝 조합',
    NBALL5: 'N 파워볼 5분',
    NBALL5_NB_MIX: 'N 파워볼 5분 일반볼 조합',
    NBALL5_PB_MIX: 'N 파워볼 5분 파워볼 조합',
    NBALL5_OE_MIX: 'N 파워볼 5분 파워볼홀짝 조합'
  },
  comp2: {
    PBG: 'PBG 파워볼',
    PBG_NB_MIX: 'PBG 일반조합',
    PBG_OE_MIX: 'PBG 파워+일반홀짝조합',
    PBG_PB_MIX: 'PBG 파워볼조합',
    PBG_NP_MIX: 'PBG 파워볼3조합',
    CPBG: 'PBG 파워볼1.93 발권기 용',
    CPBG_NB_MIX: 'PBG 일반조합 발권기 용',
    CPBG_PB_MIX: 'PBG 파워볼조합 발권기 용',
    CPBG_OE_MIX: 'PBG 파워+일반홀짝조합 발권기 용',
    CPBG_NP_MIX: 'PBG 파워볼3조합 발권기 용',
    CPBG_UO_MIX: 'PBG 파워볼 언더오버조합 발권기 용',
    CPBG_DJS: 'PBG 일반볼 대중소 발권기 용',
    CPBG_GPB: 'PBG 파워볼 맞추기 발권기 용',
    HPPB3: '해피 파워볼',
    HPPB3_NB_MIX: '해피 일반조합',
    HPPB3_PB_MIX: '해피 파워볼조합',
    HPPB3_OE_MIX: '해피 파워+일반홀짝조합',
    HPPB3_NP_MIX: '해피 파워볼 3조합',
    HPPB3_UO_MIX: '해피 파워볼 언더오바 조합',
    HPPB3_DJS: '해피 일반볼 대중소',
    HPPB3_GPB: '해피 파워볼 숫자 맞추기',
    RUNBS6: '런닝볼 스피드6',
    RUNBS6_MIX: '런닝볼 스피드6 1,2,3등 조합',
    RUNBS6_GFB: '런닝볼 스피드6 1등 맞추기',
    RUNBS4: '런닝볼 스피드4',
    RUNBS4_MIX: '런닝볼 스피드4 1,2,3등 조합',
    RUNBS4_GFB: '런닝볼 스피드4 1등 맞추기',
    RUNBM2: '런닝볼 메이즈2',
    EVPWB2: 'EVO파워볼2턴',
    EVPWB2_NB_MIX: 'EVO파워볼2턴일반조합',
    EVPWB3: 'EVO파워볼 3턴',
    EVPWB3_NB_MIX: 'EVO파워볼 3턴일반조합',
    EVPWB5: 'EVO파워볼 5턴',
    EVPWB5_NB_MIX: 'EVO파워볼 5턴일반조합',
    EPB3: 'EOS3분파워볼',
    EPB3_NB_MIX: 'EOS3분파워볼일반조합',
    EPB3_OE_MIX: 'EOS3 분파워볼파워+일본홀짝조합',
    EPB3_PB_MIX: 'EOS3분파워볼파워조합',
    EPB: 'EOS5분파워볼',
    EPB_NB_MIX: 'EOS5분파워볼일반조합',
    EPB_OE_MIX: 'EOS5 분파워볼파워+일본홀짝조합',
    EPB_PB_MIX: 'EOS5분파워볼파워조합',
    CSA3: '코인사다리3분',
    CSA3_MIX: '코인사다리3분조합',
    CSA5: '코인사다리5분',
    CSA5_MIX: '코인사다리5분조합',
    DSCP3: '코인파워볼3분',
    DSCP3_NB_MIX: '코인파워볼3분일반조합',
    DSCP3_OE_MIX: '코인파워볼3분파워+일반홀짝조합',
    DSCP3_PB_MIX: '코인파워볼3분파워조합',
    DSCP3_DJS: '코인파워볼3분대중소',
    DSCP5: '코인파워볼5분',
    DSCP5_NB_MIX: '코인파워볼5분일반조합',
    DSCP5_OE_MIX: '코인파워볼5분파워+일반홀짝조합',
    DSCP5_PB_MIX: '코인파워볼5분파워조합',
    DSCP5_DJS: '코인파워볼5분대중소',
    BNBPB3: 'BNB 3분파워볼',
    BNBPB3_NB_MIX: 'BNB 3분일반조합',
    BNBPB3_PB_MIX: 'BNB 3분파워볼조합',
    BNBPB3_OE_MIX: 'BNB 3분파워볼+일반홀짝조합',
    BNBPB3_NP_MIX: 'BNB 3분파워볼3조합',
    BNBPB5: 'BNB 5분파워볼',
    BNBPB5_NB_MIX: 'BNB 5분일반조합',
    BNBPB5_PB_MIX: 'BNB 5분파워볼조합',
    BNBPB5_OE_MIX: 'BNB 5분파워볼+일반홀짝조합',
    BNBPB5_NP_MIX: 'BNB 5분파워볼3조합',
    BNBSA3: 'BNB 3분사다리',
    BNBSA3_MIX: 'BNB 3분사다리조합',
    BNBSA5: 'BNB 5분사다리',
    BNBSA5_MIX: 'BNB 5분사다리조합',
    NNPB: 'W파워볼',
    NNPB_NB_MIX: 'W파워볼일반조합',
    NNPB_OE_MIX: 'W파워볼파워+일반홀짝조합',
    NNPB_PB_MIX: 'W파워볼파워조합',
    NNPB_NP_MIX: 'W파워볼3조합',
    KNPB: '동행키노',
    KNPB_NB_MIX: '동행키노슈퍼볼조합',
    KNPB_PB_MIX: '동행키노파워볼조합',
    KNPB_OE_MIX: '동행키노파워볼홀짝조합',
    BPWB: '보글파워볼',
    BPWB_OE_MIX: '보글파워볼 +일반 홀짝 조합',
    BPWB_NB_MIX: '보글파워볼 일반조합',
    BPWB_PB_MIX: '보글파워볼파워볼조합',
    BPWB_NP_MIX: '보글파워볼파워볼 3조합',
    BSDR: '보글사다리',
    BSDR_MIX: '보글 사다리 조합',
    DPWB: '동행파워볼',
    DPWB_NB_MIX: '동행 일반조합',
    DPWB_PB_MIX: '동행 파워볼조합',
    DPWB_OE_MIX: '동행 파워+일반홀짝조합'
  },
  comp3: {
    DPWB_NP_MIX: '동행 파워볼3조합',
    DSCB3_NM_PB: '파워볼',
    DSCB3_NM_MIX: '일반볼조합',
    DSCB3_PB_MIX: '파워볼조합',
    DSCB3_OE_MIX: '일반볼조합+ 파워볼홀짝',
    DSCB3_NM_PB_MIX: '파워볼맞추기',
    DSCB3_PNUM: '파워볼',
    DSCB5_NM_PB: '일반볼조합',
    DSCB5_NM_MIX: '파워볼조합',
    DSCB5_PB_MIX: '파워볼홀짝조합',
    DSCB5_OE_MIX: '일반볼조합+ 파워볼홀짝',
    DSCB5_NM_PB_MIX: '파워볼맞추기',
    DSCB5_PNUM: '파워볼',
    PBG_NM_PB: '일반볼조합',
    PBG_NM_MIX: '파워볼조합',
    PBG_PB_MIX: '파워볼홀짝조합',
    PBG_OE_MIX: '일반볼조합+ 파워볼홀짝',
    PBG_NM_PB_MIX: '파워볼맞추기',
    PBG_PNUM: '파워볼',
    EOSP3_NM_PB: '일반볼조합',
    EOSP3_NM_MIX: '파워볼조합',
    EOSP3_PB_MIX: '파워볼홀짝조합',
    EOSP3_OE_MIX: '일반볼조합+ 파워볼홀짝',
    EOSP3_NM_PB_MIX: '파워볼맞추기',
    EOSP3_PNUM: '파워볼',
    EOSP5_NM_PB: '일반볼조합',
    EOSP5_NM_MIX: '파워볼조합',
    EOSP5_PB_MIX: '파워볼홀짝조합',
    EOSP5_OE_MIX: '일반볼조합+ 파워볼홀짝',
    EOSP5_NM_PB_MIX: '파워볼맞추기',
    EOSP5_PNUM: '파워볼맞추기',
    BUBP2_PNUM: '파워볼맞추기',
    PBALL_NM_PB: '동행파워볼',
    PBALL_NM_MIX: '일반볼 조합',
    PBALL_PB_MIX: '파워볼 조합',
    PBALL_OE_MIX: '파워볼 홀짝조합',
    PBALL_NM_PB_MIX: '일반볼 조합 + 파워볼',
    PBALL_HML: '일반볼 대중소',
    PLADR_NM: '동행사다리',
    PLADR_MIX: '동행사다리 조합'
  },
  comp4: {
    DSCP3: '코인 파워볼 3 분',
    DSCP3_NB_MIX: '코인 파워볼 3 분 일반볼 조합',
    DSCP3_PB_MIX: '코인 파워볼 3 분 파워볼 조합',
    DSCP3_OE_MIX: '코인 파워볼 3 분 파워볼+일반볼 조합',
    DSCP3_DJS: '코인 파워볼 3 분 일반볼 대중소',
    DSCP5: '코인 파워볼 5 분',
    DSCP5_NB_MIX: '코인 파워볼 5 분 일반볼 조합',
    DSCP5_PB_MIX: '코인 파워볼 5 분 파워볼 조합',
    DSCP5_OE_MIX: '코인 파워볼 5 분 파워볼+일반볼 조합',
    DSCP5_DJS: '코인 파워볼 5 분 일반볼 대중소',
    CSA3: '코인사다리 3 분',
    CSA3_MIX: '코인사다리 3 분 조합',
    CSA5: '코인사다리 5 분',
    CSA5_MIX: '코인사다리 5 분 조합',
    EPB: 'EOS 파워볼',
    EPB_NB_MIX: 'EOS 파워볼 일반볼 조합',
    EPB_PB_MIX: 'EOS 파워볼 파워볼 조합',
    EPB_OE_MIX: 'EOS 파워볼 파워볼+일반볼 조합',
    EPB_NP_MIX: 'EOS 파워볼 파워볼홀짝+일반볼홀짝,언더오버 조합 5 분',
    EPB_PNUM: 'EOS 파워볼 맞추기 5 분',
    EPB3: 'EOS 파워볼 3 분',
    EPB3_NB_MIX: 'EOS 파워볼 일반볼 조합 3 분',
    EPB3_PB_MIX: 'EOS 파워볼 파워볼 조합 3 분',
    EPB3_OE_MIX: 'EOS 파워볼 파워볼+일반볼 조합 3 분',
    EPB3_NP_MIX: 'EOS 파워볼 파워볼홀짝+일반볼홀짝,언더오버 조합 3 분',
    EPB3_PNUM: 'EOS 파워볼 맞추기 3 분',
    KNPB: '동행 스피드 키노',
    KNPB_NB_MIX: '동행 스피드 키노 슈퍼볼 조합(일반볼조합)',
    KNPB_PB_MIX: '동행 스피드 키노 파워볼 조합',
    KNPB_OE_MIX: '동행 스피드 키노 홀짝 조합',
    KNPB_SUM: '동행 스피드 키노 숫자합',
    MTPB3: '메타 파워볼 3 분',
    MTPB3_NB_MIX: '메타 파워볼 3 분 일반볼 조합',
    MTPB3_PB_MIX: '메타 파워볼 3 분 파워볼 조합',
    MTPB3_OE_MIX: '메타 파워볼 3 분 파워볼+일반볼 조합',
    MTPB3_DJS: '메타 파워볼 3 분 일반볼 대중소',
    MTPB5: '메타 파워볼 5 분',
    MTPB5_NB_MIX: '메타 파워볼 5 분 일반볼 조합',
    MTPB5_PB_MIX: '메타 파워볼 5 분 파워볼 조합',
    MTPB5_OE_MIX: '메타 파워볼 5 분 파워볼+일반볼 조합',
    MTPB5_DJS: '메타 파워볼 3 분 일반볼 대중소',
    HSSA3: '메타 사다리 3 분',
    HSSA3_MIX: '메타 사다리 3 분 조합',
    HSSA5: '메타 사다리 5 분',
    HSSA5_MIX: '메타 사다리 5 분 조합',
    MTPB: 'PBG 파워볼',
    MTPB_NB_MIX: 'PBG 파워볼 일반볼 조합',
    MTPB_PB_MIX: 'PBG 파워볼 파워볼 조합',
    MTPB_OE_MIX: 'PBG 파워볼 파워볼+일반볼 조합',
    MTPB_NP_MIX: 'PBG 파워볼 파워볼홀짝+일반볼홀짝,언더오버 조합',
    MTPB_PNUM: 'PBG 파워볼 맞추기',
    MTSA3: 'PBG 사다리',
    MTSA3_MIX: 'PBG 사다리 조합',
    NNPB: '나눔 파워볼',
    NNPB_NB_MIX: '나눔 파워볼 일반볼 조합',
    NNPB_PB_MIX: '나눔 파워볼 파워볼 조합',
    NNPB_OE_MIX: '나눔 파워볼 파워볼+일반볼 조합',
    NNPB_NP_MIX: '나눔 파워볼 파워볼홀짝+일반볼홀짝,언더오버 조합',
    NNPB_PNUM: '나눔 파워볼 맞추기'
  }
};

export const SITE_LIST = {};

export const INSURED_COMP_INFO = {
  comp1: {
    apiUrl: 'https://rsn-111.com/api/bet'
  },
  comp2: {
    apiUrl: 'http://sb-79.com:8087/api/bet'
  },
  comp3: {
    url: 'http://nnb-1.com/game/bet.asp'
  },
  comp4: {
    url: 'http://woori-333.com:8089/api/bet'
  }
};
