import user from './children/user';
import partner from './children/partner';
export default {
  name: 'consum',
  path: '/consum',
  redirect: {
    name: 'userList'
  },
  props: true,
  meta: {
    layout: 'default',
    i18n: 'nav.manageUser',
    text: '회원관리',
    icon: 'fas fa-user-cog',
    code: 'LV010000'
  },
  children: [user, partner]
};
