export default {
  name: 'insured',
  path: '/insured',
  component: () => import('@/views/member/insured'),
  redirect: {
    name: 'insureBetList'
  },
  meta: {
    layout: 'default',
    isActive: false,
    i18n: 'nav.manageInsure',
    text: '보험 관리',
    icon: 'fas fa-handshake',
    code: 'LV040000'
  },
  children: [
    {
      name: 'insureBetSettings',
      path: '/insured/settings',
      component: () => import('@/views/member/insured/pages/settings'),
      meta: {
        menuMember: true,
        text: '보험베팅설정',
        i18n: 'tabList.insuredBetSettings',
        isActive: false,
        code: 'LV040800'
      }
    },
    {
      name: 'insureBetList',
      path: '/insured/insureBetList',
      component: () => import('@/views/member/insured/pages/insureBetList'),
      meta: {
        menuMember: true,
        text: '보험베팅내역',
        i18n: 'tabList.insuredBetList',
        isActive: false,
        code: 'LV040800'
      }
    }
    // {
    //   name: "CasinoInsureSettings",
    //   path: "/insured/casino",
    //   component: () => import("@/views/member/insured/pages/casino"),
    //   meta: {
    //     menuMember: true,
    //     text: "카지노 보험 설정",
    //     exceptType:'senior',
    //     code: "LV040100"
    //   }
    // },
    // {
    //   name: "SlotInsureSettings",
    //   path: "/insured/slot",
    //   component: () => import("@/views/member/insured/pages/slot"),
    //   meta: {
    //     menuMember: true,
    //     text: "슬롯 보험 설정",
    //     exceptType:'senior',
    //     code: "LV040200"
    //   }
    // },
    // {
    //   name: "SportInsureSettings",
    //   path: "/insured/sport",
    //   component: () => import("@/views/member/insured/pages/sport"),
    //   meta: {
    //     menuMember: true,
    //     text: "스포츠 보험 설정",
    //     exceptType:'senior',
    //     code: "LV040300"
    //   }
    // },
    // {
    //   name: "subInsureBetList",
    //   path: "/insured/subInsureBetList",
    //   component: () => import("@/views/member/insured/pages/subInsureBetList"),
    //   meta: {
    //     menuMember: true,
    //     text: "하부보험베팅내역",
    //     isActive: false,
    //     exceptType:'agency',
    //     code: "LV040900"
    //   }
    // },
    // {
    //   name: "insuredAccumulation",
    //   path: "/company/insured/accumulate",
    //   component: () => import("@/views/member/insured/pages/accumulate"),
    //   meta: {
    //     menuMember: true,
    //     text: "보험적립내역",
    //     isActive: false
    //   }
    // },
    // {
    //   name: "summaryList",
    //   path: "/company/insured/summaryList",
    //   component: () => import("@/views/member/insured/pages/summaryList"),
    //   meta: {
    //     menuMember: true,
    //     text: "보험정산내역",
    //     isActive: false,
    //     isHide:false
    //   }
    // },
    // {
    //   name: "insuredApplyMy",
    //   path: "/insured/apply/my",
    //   component: () => import("@/views/member/insured/pages/applyMy"),
    //   meta: {
    //     menuMember: true,
    //     text: "보험변화내역",
    //     isActive: false,
    //     code: "LV041000"
    //   }
    // },
    // {
    //   name: "insuredApplyList",
    //   path: "/insured/apply",
    //   component: () => import("@/views/member/insured/pages/apply"),
    //   meta: {
    //     menuMember: true,
    //     text: "하부보험금신청내역",
    //     isActive: false,
    //     exceptType:'agency',
    //     code: "LV041100"
    //   }
    // },
    // {
    //   name: "insuredExchangeList",
    //   path: "/insured/exchange",
    //   component: () => import("@/views/member/insured/pages/exchange"),
    //   meta: {
    //     menuMember: true,
    //     text: "하부보험금환전내역",
    //     isActive: false,
    //     exceptType:'agency',
    //     code: "LV041200"
    //   }
    // }
  ]
};
