import reportsPartner from './children/reportsPartner';
import { cashBoth } from './children/cash';

export default {
  name: 'reports',
  path: '/reports',
  redirect: {
    name: 'userList'
  },
  props: true,
  meta: {
    layout: 'default',
    i18n: 'nav.manageSummary',
    text: '정산관리',
    icon: 'fas fa-user-cog',
    code: 'LV010000'
  },
  children: [
    reportsPartner,
    {
      name: 'dailyReport',
      path: '/reports/dailyReport',
      component: () => import('@/views/member/reports/pages/daily'),
      meta: {
        menuMember: true,
        i18n: 'tabList.daily',
        text: '일자별 정산',
        code: 'LV080400'
      }
    },
    {
      name: 'memWinLose',
      path: '/reports/winlose',
      component: () => import('@/views/member/reports/pages/winlose'),
      meta: {
        menuMember: true,
        i18n: 'tabList.winLose',
        text: '윈루즈',
        code: 'LV080100'
      }
    },
    {
      name: 'totalList',
      path: '/reports/totalList',
      component: () => import('@/views/member/reports/pages/totalList'),
      meta: {
        menuMember: true,
        i18n: 'tabList.cashHistory',
        text: '캐시변동내역',
        code: 'LV080500'
      }
    },
    cashBoth
  ]
};
