import { format, subDays } from 'date-fns';

export function isPlainObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function isValidUserId(value) {
  const regType = /^[A-Za-z0-9+]{3,20}$/;
  return regType.test(value);
}

export function isInteger(value) {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}
export function deleteCommas(value) {
  const split = value.split(',');
  let returnVal = '';
  for (const item of split) {
    returnVal += item;
  }
  if (isNaN(returnVal)) {
    return null;
  } else {
    return Number(returnVal);
  }
}
export function upperFirst(value) {
  value = String(value);

  if (!value.length) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}
export const createAlphabetList = () => {
  const alphabet = [];
  for (let i = 65; i <= 90; i++) {
    alphabet.push(String.fromCharCode(i));
  }
  return alphabet;
};
export function cloneDeep(data) {
  return JSON.parse(JSON.stringify(data));
}
export function jsonSort(item, ord, type) {
  let _type = 'asc';
  if (type) {
    _type = type;
  }
  item.sort((a, b) => {
    if (_type === 'asc') {
      return Number(a[ord]) - Number(b[ord]);
    } else if (_type === 'desc') {
      return Number(b[ord]) - Number(a[ord]);
    } else {
      return null;
    }
  });
  return item;
}

export function getPercentage(value, total, digits) {
  if (isNaN(value) || isNaN(total) || total === 0) {
    return 0;
  }

  let result = (value / total) * 100;

  if (digits !== undefined) {
    result = Number(result.toFixed(digits));
  }
  return result;
}

export function getTimeString(value) {
  const hour = Math.floor(value / 3600);
  const minute = Math.floor(value / 60) % 60;
  const second = Math.round(value % 60);
  if (hour >= 1) {
    return `${hour}h ${minute}m ${second}s`;
  }
  if (minute >= 1) {
    return `${minute}m ${second}s`;
  }
  return `${second}s`;
}

export function getNumberWithOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function thousand(value, fixed = 0) {
  value = Number(value);
  return value.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed });
}

export function leadingZero(number) {
  return number < 10 ? '0' + number : number;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDateStr(value, defaultStr) {
  if (!defaultStr) {
    defaultStr = 'yyyy-MM-dd HH:mm:ss';
  }

  return format(value, defaultStr);
}
export function replaceDateT(dateStr) {
  const _dateStr = dateStr.replace('T', ' ');
  return _dateStr;
}
export function numberWithCommas(value, fixed = 0) {
  if (!value) {
    return 0;
  }
  if (!isNaN(Number(value))) {
    return Number(value).toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed });
  }
}
export function getSubDaysDate(value, days) {
  return subDays(value, days);
}
export function onlyNumber(e) {
  const val = e.target.value;
  e.target.value = val.replace(/[^0-9]/g, '');
  e.target.value = numberWithCommas(e.target.value);
}

export function timeConverter_seconds(seconds) {
  let timeType = '';
  if (seconds >= 60) {
    timeType = 'minutes';
  } else if (seconds >= 3600) {
    timeType = 'hours';
  } else {
    timeType = 'seconds';
  }

  let converTime = '';
  let HH = '';
  let MM = '';
  let SS = '';
  switch (timeType) {
    case 'seconds':
      converTime = '';
      break;
    case 'minutes':
      MM = Math.floor(seconds / 60);
      SS = seconds - MM * 60;

      converTime = `00:${MM}:${SS}`;
      break;
    case 'hours':
      HH = Math.floor(seconds / 3600);
      MM = Math.floor(seconds / 60);
      SS = seconds - MM * 60;

      converTime = `${HH}:${MM}:${SS}`;
      break;
  }

  return converTime;
}

let stopCount = 0;
export function stopedInterval(item) {
  console.log('interval Stop');
  clearInterval(item);
  stopCount = stopCount + 1;
}

export const listGroupBy = (item, criteria) => {
  return item.reduce((result, target) => {
    result[target[criteria]] = result[target[criteria]] || [];
    result[target[criteria]].push(target);
    return result;
  }, {});
};

export const toDecimal = value => {
  if (!isNaN(value)) {
    return Number(value).toFixed(2);
  } else {
    return 0;
  }
};

const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const validateIpv4 = ip => {
  return ipv4Regex.test(ip);
};
const ipv6Segment = '([0-9a-fA-F]{1,4})';
const ipv6Regex = new RegExp(`^(${ipv6Segment}:){7,7}${ipv6Segment}|(${ipv6Segment}:){1,7}:|(${ipv6Segment}:){1,6}:${ipv6Segment}|(${ipv6Segment}:){1,5}(:${ipv6Segment}){1,2}|(${ipv6Segment}:){1,4}(:${ipv6Segment}){1,3}|(${ipv6Segment}:){1,3}(:${ipv6Segment}){1,4}|(${ipv6Segment}:){1,2}(:${ipv6Segment}){1,5}|${ipv6Segment}:((:${ipv6Segment}){1,6})|:((:${ipv6Segment}){1,7}|:)|fe80:(:${ipv6Segment}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])|(${ipv6Segment}:){1,4}:${ipv4Regex.source}$`);
export const validateIpv6 = ip => {
  return ipv6Regex.test(ip);
};
