import { createStore } from 'vuex';
import { IS_PRODUCTION } from './libs/constants';
import { getAuthToken, getSiteData } from './libs/auth-helper';
import { getCode, gameGroupCodes, getMenuList } from '@/api/common';
import { siteOption } from '@/api/member';

const headCashModel = {
  allAmt: 0,
  beneAmt: 0,
  biAmt: 0,
  biSummaryApplyCnt: 0,
  creditAmt: 0,
  creditCnt: 0,
  creditExchangeCnt: 0,
  creditSubPoint: 0,
  insuranceCnt: 0,
  insuranceExchangeCnt: 0,
  days: '',
  inAmt: 0,
  insuredAmt: 0,
  insuredSubPoint: 0,
  outAmt: 0,
  pointAmt: 0,
  siteId: '',
  tatalGameMoney: 0,
  yallAmt: 0,
  ypointAmt: 0,
  crownCreditAmt: 0
};
const headBottomInfoModel = {
  totalMemberCountToday: 0,
  totalMemberCount: 0,
  dCasinoBetAmt: 0,
  dCasinoBetAmtWin: 0,
  dCasinoRollingAmt: 0,
  dCasinoBeneAmt: 0,
  dSlotBetAmt: 0,
  dSlotBetAmtWin: 0,
  dSlotRollingAmt: 0,
  dSlotBeneAmt: 0,
  dSportsBetAmt: 0,
  dSportsBetWinAmt: 0,
  dSportsRollingAmt: 0,
  dSportsBeneAmt: 0,
  dMiniBetAmt: 0,
  dMiniBetAmtWin: 0,
  dMiniRollingAmt: 0,
  dMiniBeneAmt: 0,
  dBetAmt: 0,
  dBetAmtWin: 0,
  dRollingAmt: 0,
  dBeneAmt: 0,
  dUserCashIn: 0,
  dUserCashOut: 0,
  dUserPayback: 0,
  dUserPayment: 0,
  fakeCasinoBetAmt: 0,
  fakeCasinoBetAmtWin: 0,
  fakeCasinoRollingAmt: 0,
  fakeSlotBetAmt: 0,
  fakeSlotBetAmtWin: 0,
  fakeSlotRollingAmt: 0
};
const headTopInfoModel = {
  cashAmt: 0,
  pointAmt: 0,
  beneAmt: 0,
  inCashManualAmt: 0,
  outCashManualAmt: 0,
  inCashAmt: 0,
  outCashAmt: 0,
  inPointManualAmt: 0,
  outPointManualAmt: 0
};
export default createStore({
  state: {
    // vue의 data객체와 같음
    logoutStack: 0,
    errorData: null,
    siteData: null,
    headCashInfo: headCashModel,
    gameList: {},
    gameGroupCodes: null,
    gameGroupList: [],
    menuData: null,
    isPT: false,
    isInsured: false,
    headerTopInfo: headTopInfoModel,
    headerBottomInfo: headBottomInfoModel,
    alarmInfo: {
      crownCreditAmt: 0
    },
    creditBalance: [],
    modal: {
      isActive: false,
      components: ''
    },
    navToggle: false,
    providers: [],
    loginMember: [],
    alarmList: [],
    underSiteList: [],
    overBetCount: {
      slot: 0,
      casino: 0,
      sports: 0,
      minigame: 0
    },
    dangerBetCount: {
      slot: 0,
      casino: 0,
      sports: 0,
      minigame: 0
    },
    pageReloadKey: 0,
    siteOptions: {}
  },
  getters: {
    creditBalance(state) {
      return state.creditBalance;
    },
    dangerBetCount(state) {
      return state.dangerBetCount;
    },
    overBetCount(state) {
      return state.overBetCount;
    },
    pageReloadKey(state) {
      return state.pageReloadKey;
    },
    providers(state) {
      return state.providers;
    },
    navToggle(state) {
      return state.navToggle;
    },
    // vue의 computed와 같이 계산된 state의 값을 가져옴
    siteData(state) {
      return state.siteData;
    },
    headCashInfo(state) {
      return state.headCashInfo;
    },
    gameList(state) {
      return state.gameList;
    },
    menuData(state) {
      return state.menuData;
    },
    modal(state) {
      return state.modal;
    },
    gameGroupCodes(state) {
      return state.gameGroupCodes;
    },
    loginMember(state) {
      return state.loginMember;
    },
    alarmList(state) {
      return state.alarmList;
    },
    siteOptions(state) {
      return state.siteOptions;
    }
  },
  mutations: {
    setPageReloadKey(state, value) {
      const kewoad = `${value}_${new Date().getTime()}`;
      state.pageReloadKey = kewoad;
    },
    setHeaderTopInfo(state, value) {
      const topInfo = value;
      if (topInfo) {
        for (const key in topInfo) {
          state.headerTopInfo[key] = topInfo[key];
        }
      }
    },
    setUnderSiteList(state, value) {
      // console.log(value);
      state.underSiteList = value;
    },
    setHeaderBottomInfo(state, value) {
      // console.log(state)
      // console.log(value)
      const bottomInfo = value;
      if (bottomInfo) {
        for (const key in bottomInfo) {
          state.headerBottomInfo[key] = bottomInfo[key];
        }
        const totalInfo = {
          dBeneAmt: bottomInfo.dBetAmt - bottomInfo.dBetAmtWin - ((bottomInfo.dSportsRollingAmt || 0) + (bottomInfo.dMiniRollingAmt || 0) + (bottomInfo.fakeSlotRollingAmt || 0) + (bottomInfo.fakeCasinoRollingAmt || 0)),
          dCasinoBeneAmt: bottomInfo.dCasinoBetAmt - bottomInfo.dCasinoBetAmtWin - bottomInfo.fakeCasinoRollingAmt,
          dSlotBeneAmt: bottomInfo.dSlotBetAmt - bottomInfo.dSlotBetAmtWin - bottomInfo.fakeSlotRollingAmt,
          dSportsBeneAmt: bottomInfo.dSportsBetAmt - bottomInfo.dSportsBetWinAmt - bottomInfo.dSportsRollingAmt,
          dMiniBeneAmt: bottomInfo.dMiniBetAmt - bottomInfo.dMiniBetAmtWin - bottomInfo.dMiniRollingAmt
        };

        for (const key in totalInfo) {
          state.headerBottomInfo[key] = totalInfo[key];
        }
      }
    },
    setAlarmInfo(state, value) {
      if (value) {
        state.alarmInfo = value;
      }
    },
    setCreditBalance(state, value) {
      if (value) {
        state.creditBalance = value;
      }
    },
    setHeadCashInfo(state, value) {
      if (value) {
        state.alarmInfo = value;
      }
    },
    setLoginMember(state, value) {
      if (!value) state.loginMember = [];
      state.loginMember = value;
    },
    setAlarmList(state, list) {
      const overBetCount = {
        slot: [],
        casino: [],
        sports: [],
        minigame: []
      };
      const dangerBetCount = {
        slot: [],
        casino: [],
        sports: [],
        minigame: []
      };
      if (!list) {
        state.alarmList = [];
      } else {
        state.alarmList = list;
        // 데이터 푸쉬전 초기화
        // console.log(state.overBetCount);
        // console.log(state.dangerBetCount);
        list.forEach(item => {
          try {
            if (item.alarmType === 'bet') {
              overBetCount[item.gameCategory].push(item);
            }
            if (item.alarmType === 'user') {
              dangerBetCount[item.gameCategory].push(item);
            }
          } catch (e) {
            console.log(e);
          }
        });
      }
      state.overBetCount = {
        slot: overBetCount.slot.length,
        casino: overBetCount.casino.length,
        sports: overBetCount.sports.length,
        minigame: overBetCount.minigame.length
      };
      state.dangerBetCount = {
        slot: dangerBetCount.slot.length,
        casino: dangerBetCount.casino.length,
        sports: dangerBetCount.sports.length,
        minigame: dangerBetCount.minigame.length
      };
    },
    // state 값 변경 이벤트 로직 또는 메소드 ..commit()으로 동작, state의 상태변경을 위한 동기처리 로직을 담는다.
    setSiteData(state, value) {
      // console.log(state);
      state.siteData = value;
    },
    setHeaderInfo(state, info) {
      if (!info) return;
      const headCashInfo = JSON.parse(JSON.stringify(state.headCashInfo));
      state.headCashInfo = Object.assign(headCashInfo, info);
      for (const key in state.headCashInfo) {
        state.headCashInfo[key] = info[key];
      }
    },
    setErrorData(state, payload) {
      state.errorData = payload;
    },
    setProviders(state, payload) {
      // crownCreditAmt, heavenCreditAmt, btiCreditAmt, api77CreditAmt, inplayCreditAmt, pinacleCreditAm
      const providers = payload;
      providers.forEach(item => {
        item.vendorCreditKeyword = `${item.code}CreditAmt`;
      });
      state.providers = providers;
    },
    clearAllSiteData(state) {
      state.siteData = null;
      state.headCashInfo = headCashModel;
    },
    setGameList(state, gamesObj) {
      state.gameList = gamesObj;
    },
    setMenuData(state, value) {
      state.menuData = value;
    },
    setIsPT(state, value) {
      state.isPT = value;
    },
    setIsInsured(state, value) {
      state.isInsured = value;
    },
    setGameGroupCodes(state, value) {
      const categoryOnly = [];
      for (const i in value) {
        categoryOnly.push(value[i].groupCode);
      }
      this.state.gameGroupList = categoryOnly;
      this.state.gameGroupCodes = value;
    },
    setModal(state, value) {
      // console.log('setfdfdfdf')
      state.modal.isActive = value.isActive;
      state.modal.components = value.components;
    },
    setNavToggle(state, value) {
      // console.log(state)
      // console.log(value)
      state.navToggle = value;
    },
    setOptions(state, siteOptions) {
      state.siteOptions = { ...siteOptions.option };
    }
  },
  actions: {
    async siteInitialize({ commit }) {
      try {
        const siteData = JSON.parse(getSiteData());
        const req = {
          siteId: siteData.siteId
        };
        const response = await siteOption(req);
        // console.log(response);
        const { resultCode, resultMessage, data } = response;
        if (resultCode === '200') {
          console.log('Site Option initialize ok');
          commit('setOptions', data);
        } else {
          console.log('Site Option initialize fail');
          console.log(resultMessage);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async storeProviders({ commit }) {
      const reponse = await getCode('vendorComp');
      // console.log(reponse)
      commit('setProviders', reponse);
    },
    setNavToggle(state, value) {
      // console.log(state)
      // console.log(value)
      state.navToggle = value;
    },
    setModal({ commit }, value) {
      // console.log(value)
      commit('setModal', value);
    },
    storeHeaderTopInfo({ commit }, payload) {
      commit('setHeaderInfo', payload);
    },
    storeAlarmInfo({ commit }, headCashInfo, loginMember) {
      commit('setAlarmInfo', headCashInfo);
    },
    // store에 메소드를 등록하는 용도, state의 상태변경을 위한 비동기 처리 로직을 담는다.
    storeSiteData({ dispatch, commit }, payload) {
      const authToken = getAuthToken();
      if (authToken) {
        const siteData = JSON.parse(getSiteData());
        commit('setSiteData', siteData);
      }
    },
    inputHeadCashInfo({ commit }, headInfo) {
      commit('setHeaderInfo', headInfo);
    },
    storeGameGroupCodes({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'procId',
        siteId: this.state.siteData.siteId
      };
      if (this.state.gameGroupCodes) {
        return this.state.gameGroupCodes;
      } else {
        return gameGroupCodes(payload).then(response => {
          const data = response.data;
          commit('setGameGroupCodes', data.data.groupCodes);
          return data;
        });
      }
    },
    async storeMenuDate({ dispatch, commit }, payload) {
      await getMenuList({}).then(res => {
        const menuObj = {};

        for (let i = 0, iLen = res.length; i < iLen; i++) {
          const menuItem = res[i];
          const code = menuItem.menuCode;
          if (!menuObj[code]) {
            if (menuItem.useYn === 'Y') {
              menuObj[code] = menuItem;
              if (code === 'LV000001') {
                this.LV000001 = true;
                commit('setIsPT', true);
              }
              if (code === 'LV000002') {
                this.LV000002 = true;
                commit('setIsInsured', true);
              }
            }
          }
        }
        // console.log(menuObj)
        commit('setMenuData', menuObj);
      });
    }
  },
  strict: IS_PRODUCTION
});
