export default {
  name: 'Site',
  path: '/settings/site',
  component: () => import('@/views/member/site'),
  props: true,
  meta: {
    menuMember: true,
    i18n: 'tabList.siteSettings',
    text: '사이트 설정',
    code: 'LV090400'
  },
  redirect: {
    name: 'SiteOptions'
  },
  children: [
    {
      name: 'SiteOptions',
      path: '/settings/site/options',
      component: () => import('@/views/member/site/pages/options'),
      meta: {
        tabMenu: true,
        text: '사이트 옵션',
        isActive: false,
        code: 'LV090404'
      }
    },
    {
      name: 'SiteBlock',
      path: '/settings/site/block',
      component: () => import('@/views/member/site/pages/block'),
      meta: {
        tabMenu: true,
        text: '사이트 옵션',
        isActive: false,
        code: 'LV090404'
      }
    },
    {
      name: 'SiteFronBanner',
      path: '/settings/site/front_banner',
      component: () => import('@/views/member/site/pages/front_banner'),
      meta: {
        tabMenu: true,
        text: '메인배너 설정',
        isActive: false,
        code: 'LV090401'
      }
    },
    {
      name: 'SiteFakeMain',
      path: '/settings/site/fakeMain',
      component: () => import('@/views/member/site/pages/mainFakeSetting'),
      meta: {
        tabMenu: true,
        text: '메인목록관리',
        isActive: false,
        code: 'LV090401'
      }
    },
    {
      name: 'SiteBank',
      path: '/settings/site/bank',
      component: () => import('@/views/member/site/pages/bank'),
      meta: {
        tabMenu: true,
        text: '은행',
        isActive: false,
        code: 'LV090201'
      }
    },
    {
      name: 'SitemMintenance',
      path: '/settings/site/maintenance',
      component: () => import('@/views/member/site/pages/maintenance'),
      meta: {
        tabMenu: true,
        text: '사이트 점검 등록',
        isActive: false,
        code: 'LV090403'
      }
    }
  ]
};
