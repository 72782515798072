import { createWebHistory, createRouter } from 'vue-router';
import { DOCUMENT_TITLE_PREFIX } from '@/libs/constants';
import { getSiteInfo } from '@/libs/auth-helper';
import consum from './consum';
import { bet } from './bet';
import reports from './_reports';
import manage from './manage';
import insure from './insure';
import game from './game';
import admin from './admin';
const routeMembers = [consum, bet, reports, manage, game, insure, admin];

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views'),
    meta: {
      layout: 'default',
      guest: true
    },
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login'),
    meta: {
      layout: 'login',
      guest: true,
      bodyClass: 'loginBody'
    }
  },
  {
    name: 'userDetail',
    path: '/user/detail',
    component: () => import('@/views/popup/userDetail'),
    meta: {
      layout: 'popup',
      guest: false
    }
  },
  {
    name: 'userDetailPopup',
    path: '/user/detail/popup/:page',
    component: () => import('@/views/popup/userDetail/detailPopup'),
    meta: {
      layout: 'popup',
      guest: false
    },
    props: true
  },
  // {
  //   name: 'partnerDetail',
  //   path: '/partner/detail',
  //   component: () => import('@/views/popup/partnerDetail'),
  //   meta: {
  //     layout: 'popup',
  //     guest: false
  //   }
  // },
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/member'),
    meta: {
      guest: false
    },
    redirect: 'user',
    children: routeMembers
  }
];
// console.log(routes);
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  let title = DOCUMENT_TITLE_PREFIX;
  const siteInfo = getSiteInfo();
  if (siteInfo) {
    title = siteInfo.siteId;
  }
  document.title = title;
  next();
});

export default router;
