import httpClient from '@/libs/http-client'

const nullToQuote = (data) => {
  return !data ? '' : data
}

export function getCode(masterCode, groupCode, code) {
  const url = '/api/common/code'

  const data = {
    masterCode: nullToQuote(masterCode),
    groupCode: nullToQuote(groupCode),
    code: nullToQuote(code)
  }

  return httpClient.post(url, data).then(response => {
    return response.data.data.codes
  })
}

export function getMenuList() {
  const url = '/api/admin/getMenuList'


  return httpClient.post(url, {}).then(response => {
    return response.data.data.menuList
  })
}

export function gameGroupCodes (params) {
  const url = '/api/admin/gameGroupCodes'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
