<template>
  <article class="notification-container">
    <div class="app-header navbar">
      <ul class="nav navbar-nav admin-header">
        <li class="nav-item">
          <router-link :to="{ name: 'loginList' }" class="">
            {{ $t('table.head.loginUser') }}
            <span class="badge badge-default">{{ numberWithCommas(loginMember.length) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'userSignUpList' }" class="">
            {{ $t('table.head.newMember') }}
            <span class="badge badge-default">{{ numberWithCommas(headerBottomInfo.totalMemberCountToday) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'userList' }" class="">
            {{ $t('table.head.allMember') }}
            <span class="badge badge-default">{{ numberWithCommas(headerBottomInfo.totalMemberCount) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'userList' }" class="">
            {{ $t('table.head.allAmt') }}
            <span class="badge badge-default">{{ numberWithCommas(Math.floor(headerTopInfo.cashAmt)) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'userList' }" class="">
            {{ $t('table.head.allPoint') }}
            <span class="badge badge-default">{{ numberWithCommas(Math.floor(headerTopInfo.pointAmt)) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'depositReport' }" class="">
            {{ $t('table.head.deposit') }}
            <span class="badge badge-default">{{ numberWithCommas(headCashInfo.inAmt) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'withdrawReport' }" class="">
            {{ $t('table.head.withdraw') }}
            <span class="badge badge-default">{{ numberWithCommas(headCashInfo.outAmt) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a>
            {{ $t('cash.profitLoss') }}
            <span class="badge badge-default">{{ numberWithCommas(headerTopInfo.beneAmt) }}</span>
          </a>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'adminReport', query: { searchType: 'cash' } }" class="">
            {{ $t('cash.ManualPayment') }}
            <span class="badge badge-default">{{ numberWithCommas(headerTopInfo.inCashManualAmt) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'adminReport', query: { searchType: 'cash' } }" class="">
            {{ $t('cash.ManualCollection') }}
            <span class="badge badge-default">{{ numberWithCommas(Math.abs(headerTopInfo.outCashManualAmt)) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'adminReport', query: { searchType: 'point' } }" class="">
            {{ $t('cash.ManualPaymentPoint') }}
            <span class="badge badge-default">{{ numberWithCommas(headerTopInfo.inPointManualAmt) }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'adminReport', query: { searchType: 'point' } }" class="">
            {{ $t('cash.ManualCollectionPoint') }}
            <span class="badge badge-default">{{ numberWithCommas(Math.abs(headerTopInfo.outPointManualAmt)) }}</span>
          </router-link>
        </li>
        <li data-v-b823be4e="" class="nav-item">
          <router-link :to="{ name: 'dailyReport' }">
            {{ $t('user.bettingToday') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dBetAmt) }}
            </span>
          </router-link>
        </li>
        <li data-v-b823be4e="" class="nav-item">
          <router-link :to="{ name: 'dailyReport' }">
            {{ $t('user.betWinToday') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dBetAmtWin) }}
            </span>
          </router-link>
        </li>
        <li data-v-b823be4e="" class="nav-item">
          <router-link :to="{ name: 'dailyReport' }">
            {{ $t('user.betRollingToday') }}
            <span class="badge badge-default"> {{ numberWithCommas(Number(headerBottomInfo.fakeSlotRollingAmt || 0) + Number(headerBottomInfo.fakeCasinoRollingAmt || 0) + Number(headerBottomInfo.dSportsRollingAmt || 0) + Number(headerBottomInfo.dMiniRollingAmt || 0)) }} </span>
          </router-link>
        </li>
        <li data-v-b823be4e="" class="nav-item">
          <router-link :to="{ name: 'dailyReport' }">
            {{ $t('user.betCalc') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dBeneAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ onAlarm: alarmInfo.qnaReqCnt > 0 }">
          <!-- <span class="rounded-circle badge badge-pill badge-danger faa-flash animated">N</span> -->
          <a href="/board/qna/list">
            {{ $t('user.customer') }}
            <span class="badge badge-default">
              {{ numberWithCommas(alarmInfo.qnaReqCnt) }}
              ({{ numberWithCommas(alarmInfo.qnaWaitCnt) }})
            </span>
          </a>
        </li>
        <li class="nav-item" :class="{ onAlarm: alarmInfo.memJoinReqCnt > 0 }">
          <!-- <span class="rounded-circle badge badge-pill badge-danger faa-flash animated">N</span> -->
          <a href="/user/signUpList">
            {{ $t('user.MemberAuthentication') }}
            <span class="badge badge-default">
              {{ numberWithCommas(alarmInfo.memJoinReqCnt) }}
              ({{ numberWithCommas(alarmInfo.memJoinWaitCnt) }})
            </span>
          </a>
        </li>
        <!-- <li data-v-b823be4e="" class="nav-item">
          <router-link :to="{name:'userOutApply'}">
            {{ $t("user.allMembers") }}
            <span class="badge badge-default">{{ numberWithCommas(headerTopInfo.memJoinCnt) }}</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div class="app-header navbar second-navbar">
      <ul class="nav navbar-nav admin-header">
        <li class="nav-item" :class="{ dangerUser: dangerBetCount.casino > 0 }">
          <a href="/bet/casino/list/all?betAlarmType=dangerBet">
            {{ $t('table.body.casinoBet') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dCasinoBetAmt) }}
            </span>
          </a>
        </li>
        <li class="nav-item" :class="{ limitOver: overBetCount.casino > 0 }">
          <a href="/bet/casino/list/all?betAlarmType=overBet">
            {{ $t('table.body.casinoWin') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dCasinoBetAmtWin) }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameCasino' }">
            {{ $t('table.body.casinoCharge') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.fakeCasinoRollingAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameCasino' }">
            {{ $t('table.body.casinoCal') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dCasinoBeneAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ dangerUser: dangerBetCount.slot > 0 }">
          <a href="/bet/slot/list/all?betAlarmType=dangerBet">
            {{ $t('table.body.slotBet') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dSlotBetAmt) }}
            </span>
          </a>
        </li>
        <li class="nav-item" :class="{ limitOver: overBetCount.slot > 0 }">
          <a href="/bet/slot/list/all?betAlarmType=overBet">
            {{ $t('table.body.slotWin') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dSlotBetAmtWin) }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameSlot' }">
            {{ $t('table.body.slotCharge') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.fakeSlotRollingAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameSlot' }">
            {{ $t('table.body.slotCal') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dSlotBeneAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ dangerUser: dangerBetCount.sports > 0 }">
          <a href="/bet/sports/list/all?betAlarmType=dangerBet">
            {{ $t('table.body.sportsBet') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dSportsBetAmt) }}
            </span>
          </a>
        </li>
        <li class="nav-item" :class="{ limitOver: overBetCount.sports > 0 }">
          <a href="/bet/sports/list/all?betAlarmType=overBet">
            {{ $t('table.body.sportsWin') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dSportsBetWinAmt) }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameSport' }">
            {{ $t('table.body.sportsCharge') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dSportsRollingAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameSport' }">
            {{ $t('table.body.sportsCal') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dSportsBeneAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ dangerUser: dangerBetCount.minigame > 0 }">
          <a href="/bet/minigame/list/all?betAlarmType=dangerBet">
            {{ $t('table.body.miniBet') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dMiniBetAmt) }}
            </span>
          </a>
        </li>
        <li class="nav-item" :class="{ limitOver: overBetCount.minigame > 0 }">
          <a href="/bet/minigame/list/all?betAlarmType=overBet">
            {{ $t('table.body.miniWin') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dMiniBetAmtWin) }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameMiniGame' }">
            {{ $t('table.body.miniCharge') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dMiniRollingAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'betGameMiniGame' }">
            {{ $t('table.body.miniCal') }}
            <span class="badge badge-default">
              {{ numberWithCommas(headerBottomInfo.dMiniBeneAmt) }}
            </span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ onAlarm: alarmInfo.cashInReqCnt > 0 }">
          <router-link to="/user/cash/in/apply">
            {{ $t('button.rechargeApply') }}
            <span class="badge badge-default">
              {{ numberWithCommas(alarmInfo.cashInReqCnt) }}
              ({{ numberWithCommas(alarmInfo.cashInWaitCnt) }})
            </span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ onAlarm: alarmInfo.cashOutReqCnt > 0 }">
          <router-link to="/user/cash/out/apply">
            {{ $t('button.exchApply') }}
            <span class="badge badge-default">
              {{ numberWithCommas(alarmInfo.cashOutReqCnt) }}
              ({{ numberWithCommas(alarmInfo.cashOutWaitCnt) }})
            </span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="app-header betAlarm">
      <div class="betAlarm__container">
        <div class="betAlarm__item fc-acc-orange">* {{ $t('common.dangerBet') }}</div>
        <div class="betAlarm__item fc-acc-blue">* {{ $t('common.overBet') }}</div>
      </div>
    </div>
  </article>
</template>
<script>
import { removeAuthData, getSiteData, setLangCookie } from '@/libs/auth-helper';
import { mapMutations, mapState } from 'vuex';
import { headerCashInfo } from '@/api/member';
import store from '@/store';
import { numberWithCommas } from '../../../libs/utils';
export default {
  name: 'HeaderInfo',
  data() {
    return {
      siteId: '',
      headTopInfo: {}
    };
  },
  methods: {
    numberWithCommas,
    // todo : 햄버거 메뉴 변경
    ...mapMutations(['setErrorData', 'clearAllSiteData']),
    signOut() {
      this.clearAllSiteData();
      removeAuthData();

      location.href = '/login';
    },
    getSiteName() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    }
  },
  computed: {
    ...mapState(['headerTopInfo', 'headerBottomInfo', 'loginMember', 'dangerBetCount', 'overBetCount']),
    ...mapState(['menuData', 'isPT', 'isInsured', 'alarmInfo', 'navToggle', 'headCashInfo']),
    siteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        return JSON.parse(siteInfoCookie);
      } else {
        return '';
      }
    }
  },
  async created() {
    // this.siteInfo = this.getSiteName();
    const res = await headerCashInfo();
    if (res.resultCode === '200') {
      const { headCashInfo, loginMember, alarmList, creditBalance } = res.data;
      store.commit('setAlarmInfo', headCashInfo);
      store.commit('setHeaderInfo', headCashInfo);
      store.commit('setLoginMember', loginMember);
      store.commit('setAlarmList', alarmList);
      store.commit('setCreditBalance', creditBalance);
    }
    // console.log(this.headerTopInfo)
    // console.log(this.alarmInfo)
  }
};
</script>

<style scoped>
.logoWrap {
  background: #353535;
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.using-site-info.logo {
  font-weight: 900;
  font-size: 29px;
  color: #fff;
}

.notification-container {
  align-items: center;
  overflow-x: scroll;
  width: 100%;
  background-color: #fff;
}

.credit-wrap {
  width: 220px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}

.credit-wrap .title {
  font-weight: 800;
  color: #ffe000;
}

.credit-wrap .content-val {
  font-weight: 800;
  color: #feb869;
}

.notification-wrap {
  width: calc(100% - 240px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}

.noti-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}

.noti-item:last-child {
  margin-right: 0;
  background: #353535;
}

.noti-item:last-child span span {
  color: #fff;
}

.noti-item .text {
}

.noti-item:last-child .contents-box {
  color: #fff;
}

.title-box {
  margin-right: 10px;
  background: #353535;
  padding: 4px 10px;
  color: #fff;
  font-size: 14px;
}

.contents-box {
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  color: #353535;
}

.contents-box:last-child {
  margin: 0;
}

.contents-box span span {
}

.content-mono {
  margin-right: 4px;
}

a {
  color: #fff;
}

a:hover {
  font-weight: 800;
}

.navbar-nav.admin-header {
  display: flex;
  flex-direction: row;
  margin: 5px 0 0 0px;
  -webkit-transition-duration: 0.25s, 0.25s;
  transition-duration: 0.25s, 0.25s;
  -webkit-transition-property: margin-left, margin-right;
  transition-property: margin-left, margin-right;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.navbar-nav.admin-header .nav-item {
  display: inline-block;
  vertical-align: top;
  //margin-bottom: 8px !important;
  height: 39px;
}

.app-header {
  -webkit-transition-duration: 0.25s, 0.25s;
  transition-duration: 0.25s, 0.25s;
  -webkit-transition-property: margin-left, margin-right;
  transition-property: margin-left, margin-right;
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.navbar-toggler {
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
}

.nav-item {
  position: relative;
  min-width: 50px;
  margin: 0 !important;
  text-align: center;
}

.nav-item .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-link {
  cursor: pointer;
}

.navbar-toggler {
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
}

.nav-link {
  display: block;
  padding: 10px 15px;
}

.navbar-nav.admin-header .nav-item {
  min-width: 90px;
  max-width: 90px;
  box-sizing: border-box;
  border: 1px solid #9e9e9e;
  display: inline-block;
  vertical-align: top;
}

.nav-item {
  position: relative;
  min-width: 50px;
  margin: 0 !important;
  text-align: center;
}

.nav-item {
  position: relative;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav.admin-header .nav-item > a {
  white-space: nowrap;
  font-size: 12px;
  color: #9e9e9e;
  font-weight: 700;
}

.navbar-nav.admin-header .nav-item > a > span {
  display: block;
  font-size: 14px;
}

span.badge {
  text-shadow: 1px 1px 1px #404040;
}

.nav-item .badge {
  font-weight: 400;
}

.badge-success {
  background-color: #4dbd74;
}

.badge-default {
  background-color: #b0bec5;
}

.badge-primary {
  background-color: #20a8d8;
}

.badge-danger {
  background-color: #f86c6b;
}
.badge-purple {
  background-color: #9c259c;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.app-header.second-navbar {
  top: 55px;
  z-index: 999;
}

.app-header {
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header.second-navbar:before {
  box-shadow: 0 0 0 #fff;
  -webkit-box-shadow: 0 0 0 #fff;
  background-color: #fff;
}

.nav-item > .badge {
  position: absolute;
  top: 2px;
  left: 3px;
  font-size: 10px;
  width: 16px;
  height: 16px;
  padding: 0;
  line-height: 16px;
}

.faa-flash.animated,
.faa-flash.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-flash {
  -webkit-animation: flash 2s ease infinite;
  animation: flash 2s ease infinite;
}

.rounded-circle {
  border-radius: 50%;
}
.onAlarm,
.onAlarm .badge {
  animation: blink-effect 750ms step-end infinite;
}
.onAlarm span,
.onAlarm a,
.dangerUser span,
.dangerUser a,
.limitOver span,
.limitOver a {
  animation: blink-effect-font 750ms step-end infinite;
}
@keyframes blink-effect {
  50% {
    background: red;
  }
}
@keyframes blink-effect-font {
  50% {
    color: #fff;
  }
}

.alarmDisplay {
  color: #fff;
  flex: 1 1 50%;
  font-size: 12px;
  background: #b0bec5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px #404040;
}

@keyframes danger-effect {
  50% {
    background: #f2b231;
  }
}
@keyframes overlimit-effect {
  50% {
    background: #508eff;
  }
}

.dangerUser,
.dangerUser .badge {
  animation: danger-effect 750ms step-end infinite;
}
.limitOver,
.limitOver .badge {
  animation: overlimit-effect 750ms step-end infinite;
}

.betAlarm__container {
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  padding: 5px 0;
  gap: 10px;
}
</style>
