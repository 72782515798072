export default {
  name: 'gameSetting',
  path: '/gameSetting',
  component: () => import('@/views/member/game'),
  redirect: {
    name: 'CasinoSettings'
  },
  meta: {
    menuMember: true,
    i18n: 'nav.manageGame',
    text: '게임관리',
    code: 'LV090000'
  },
  children: [
    {
      name: 'CasinoSettings',
      path: '/settings/game/casino',
      component: () => import('@/views/member/game/pages/casino/settings'),
      meta: {
        tabMenu: true,
        i18n: 'table.body.casinoSetting',
        text: '카지노게임 설정관리',
        code: 'LV090501'
      }
    },
    {
      name: 'SlotSettings',
      path: '/settings/game/slot',
      component: () => import('@/views/member/game/pages/slot/settings'),
      meta: {
        tabMenu: true,
        i18n: 'table.body.slotSetting',
        text: '슬롯게임 설정관리',
        code: 'LV090502'
      }
    },
    {
      name: 'MinigameSettings',
      path: '/settings/game/minigame',
      component: () => import('@/views/member/game/pages/minigame/settings'),
      meta: {
        tabMenu: true,
        i18n: 'table.body.minigameSetting',
        text: '미니게임 설정관리',
        code: 'LV090503'
      }
    },
    {
      name: 'SportSettings',
      path: '/settings/game/sports',
      component: () => import('@/views/member/game/pages/sports'),
      meta: {
        tabMenu: true,
        i18n: 'table.body.sportsSetting',
        text: '스포츠게임 설정관리',
        code: 'LV090503'
      },
      redirect: { name: 'SportSettingsBasic' },
      children: [
        {
          name: 'SportSettingsBasic',
          path: '/settings/game/sports/settings/basic',
          component: () => import('@/views/member/game/pages/sports/basic'),
          meta: {
            tabMenu: true,
            text: '스포츠 기본설정',
            code: 'LV090503'
          }
        },
        {
          name: 'SportSettingMatch',
          path: '/settings/game/sports/settings/match',
          component: () => import('@/views/member/game/pages/sports/match'),
          meta: {
            tabMenu: true,
            text: '게시전 경기',
            code: 'LV090503'
          }
        },
        {
          name: 'SportSettingsWinRate',
          path: '/settings/game/sports/settings/winRate',
          component: () => import('@/views/member/game/pages/sports/winRate'),
          meta: {
            tabMenu: true,
            text: '배당 설정',
            code: 'LV090503'
          }
        },
        {
          name: 'SportSettingsCombination',
          path: '/settings/game/sports/settings/combination',
          component: () => import('@/views/member/game/pages/sports/combination'),
          meta: {
            tabMenu: true,
            text: '조합 조건 설정',
            code: 'LV090503'
          }
        },
        {
          name: 'SportSettingsLeague',
          path: '/settings/game/sports/settings/league',
          component: () => import('@/views/member/game/pages/sports/league'),
          meta: {
            tabMenu: true,
            text: '리그 관리',
            code: 'LV090503'
          }
        },
        // {
        //   name: 'SportSettingsHandwriting',
        //   path: '/settings/game/sports/settings/handwriting',
        //   component: () => import('@/views/member/game/pages/sports/handwriting'),
        //   meta: {
        //     tabMenu: true,
        //     text: '경기 수동 등록',
        //     code: 'LV090503'
        //   }
        // },
        // {
        //   name: 'SportSettingsPreOpen',
        //   path: '/settings/game/sports/settings/preOpen',
        //   component: () => import('@/views/member/game/pages/sports/preOpen'),
        //   meta: {
        //     tabMenu: true,
        //     text: '게시전 경기',
        //     code: 'LV090503'
        //   }
        // },
        // {
        //   name: 'SportSettingsGoingOn',
        //   path: '/settings/game/sports/settings/goingOn',
        //   component: () => import('@/views/member/game/pages/sports/goingOn'),
        //   meta: {
        //     tabMenu: true,
        //     text: '진행중 경기',
        //     code: 'LV090503'
        //   }
        // },
        {
          name: 'SportSettingsRollback',
          path: '/settings/game/sports/settings/rollback',
          component: () => import('@/views/member/game/pages/sports/rollbackList'),
          meta: {
            tabMenu: true,
            text: '역정산 목록',
            code: 'LV090503'
          }
        }
      ]
    }
  ]
};
