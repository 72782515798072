<template>
  <div class="loadmodal" v-if="isLoading">
    <div class="cloading"></div>
  </div>
</template>

<script>

export default {
  name: 'Loading',
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  mounted () {
    this.emitter.on('Loading', (data) => {
      // console.log('Loading', data)
      this.isLoading = data

      // document.body.style.overflow = this.isLoading ? 'hidden' : 'inherit'
    })
    // document.body.style.overflow = 'hidden'
  }
}
</script>
