import { createApp } from 'vue';
import router from './router/index';
import i18n from './i18n';
import store from './store';
import common from '@/plugins/common';
import App from './App.vue';
import { authBundle } from './libs/auth-helper';
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@/assets/css/reset.css';
import '@/assets/css/style.css';
import '@/assets/css/style_pre.css';
import '@/assets/css/fontAwsome.css';

import mitt from 'mitt';

const app = createApp(App);

const emitter = mitt();

app.config.globalProperties.emitter = emitter;

// router.beforeEach((to, from, next) => {
//   console.log(to);
//   // next();
//   const guest = to.meta.guest;
//   console.log(guest);
//   if(guest){

//   }else{
//     const token = getAuthToken();
//     const site = getSiteData();
//     console.log(token);
//     console.log(!site)
//     console.log(!token)
//     if (!token || !site) {
//       console.log('removeData')
//       removeAuthData;
//       return {name:'login'}
//     }

//   }

//   // console.log(to)
//   // if (guest) {
//   //   to({name:'login'})
//   // } else {

//   // }
// });
app.use(store).use(CKEditor);
app.use(i18n);
app.use(router);
app.use(authBundle);
app.mixin(common);

app.mount('body');
