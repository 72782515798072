import gameSetting from './children/gameSetting';
export default {
  name: 'game',
  path: '/game',
  redirect: {
    name: 'CasinoSettings'
  },
  meta: {
    layout: 'default',
    menuMember: true,
    i18n: 'nav.manageGame',
    text: '게임 관리',
    code: 'LV090000'
  },
  children: [gameSetting]
};
