<template>
  <div class="sub-title">
    <h3>{{text}}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageSubTitle',
  props: ['text']
}
</script>

<style scoped>
.sub-title {
  font-size: 14px;
  font-weight: bold;
  color: #353535;
  height: 45px;
  position: relative;
  border-bottom: 1px solid #e1e0e0;
  display:flex;
  align-items: center;
  padding:0 20px;
}
.sub-title > h3 {
  display: flex;
  height: 100%;
  align-items: center;
  width: 130px;
}
</style>
