<template>
  <header>
    <div class="header-upper">
      <noti-company />
    </div>
  </header>
</template>

<script>
import notiCompany from './Noti_company';
import { getSiteData } from '@/libs/auth-helper';
import { headerTopInfo } from '@/api/member';
import store from '@/store';
export default {
  name: 'HeaderArea',
  components: {
    notiCompany
  },
  methods: {
    getSiteName() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    }
  },
  data() {
    return {};
  },
  async created() {
    this.siteInfo = this.getSiteName();

    const res2 = await headerTopInfo();
    if (res2.resultCode === '200') {
      // todo: headTopInfo 뭐임
      store.commit('setHeaderTopInfo', res2.data.headerTopInfo);
      // console.log(res2.data.underSiteInfo);
      store.commit('setUnderSiteList', res2.data.underSiteInfo);
    }
  }
};
</script>

<style scoped>
.header-upper,
.header-lower {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* height: 60px; */
}
.header-upper {
}
.header-lower {
  padding: 8px 10px;
}
.mId {
  font-weight: 800;
  color: #000;
  font-size: 16px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.logo {
  font-size: 1.35em;
  font-weight: 800;
  width: 220px;
  padding-left: 15px;
  box-sizing: border-box;
}
.logo a {
  color: #fff;
}
.langs-wrap {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #fff;
}
.langs-wrap .title {
  font-weight: 700;
  margin-right: 6px;
  font-size: 0.875em;
}
.selLang {
  height: 18px;
}

.btn-credit-apply {
  font-size: 0.75em;
  border: 1px solid #18698b;
  box-sizing: border-box;
  background: #18698b;
  color: #fff;
  padding: 3px 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  flex: 1 1 0;
}
.btn-credit-apply:hover {
  color: #18698b;
  background: #fff;
}
.user-info-wrap {
  box-sizing: border-box;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.user-info-wrap:last-child {
  margin: 0;
}
.user-info-wrap .title {
  font-size: 0.8em;
  color: #000;
  font-weight: 700;
}
.user-info-wrap .contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
