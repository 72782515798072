<template>
  <!--  <audio ref="memJoinReqCnt" autoplay loop muted id="memJoinReqCnt">-->
  <!--    <source src="../../../assets/media/sound_6.wav" type="audio/wav" />-->
  <!--  </audio>-->
  <!--  <audio ref="cashInReqCnt" autoplay loop muted id="cashInReqCnt">-->
  <!--    <source src="../../../assets/media/sound_2.mp3" type="audio/wav" />-->
  <!--  </audio>-->
  <!--  <audio ref="cashOutReqCnt" autoplay loop muted id="cashOutReqCnt">-->
  <!--    <source src="../../../assets/media/sound_2.mp3" type="audio/wav" />-->
  <!--  </audio>-->
  <!--  <audio ref="creditCnt" autoplay loop muted id="creditCnt">-->
  <!--    <source src="../../../assets/media/sound_4.mp3" type="audio/wav" />-->
  <!--  </audio>-->
  <!--  <audio ref="creditExchangeCnt" autoplay loop muted id="creditExchangeCnt">-->
  <!--    <source src="../../../assets/media/sound_4.mp3" type="audio/wav" />-->
  <!--  </audio>-->
  <!--  <audio ref="qnaReqCnt" autoplay loop muted id="qnaReqCnt">-->
  <!--    <source src="../../../assets/media/sound_1.mp3" type="audio/wav" />-->
  <!--  </audio>-->
  <!--  <audio ref="insuranceCnt" autoplay loop muted id="insuranceCnt">-->
  <!--    <source src="../../../assets/media/sound_3.mp3" type="audio/wav" />-->
  <!--  </audio>-->
  <!--  <audio ref="insuranceExchangeCnt" autoplay loop muted id="insuranceExchangeCnt">-->
  <!--    <source src="../../../assets/media/sound_3.mp3" type="audio/wav" />-->
  <!--  </audio>-->
  <audio ref="qnaReqCnt_1" autoplay loop muted id="qnaReqCnt_1">
    <source src="@/assets/media/sound_1.mp3" type="audio/wav" />
  </audio>
  <audio ref="qnaReqCnt_2" autoplay loop muted id="qnaReqCnt_2">
    <source src="@/assets/media/sound_2.mp3" type="audio/wav" />
  </audio>
  <audio ref="qnaReqCnt_3" autoplay loop muted id="qnaReqCnt_3">
    <source src="@/assets/media/sound_3.mp3" type="audio/wav" />
  </audio>
  <audio ref="qnaReqCnt_4" autoplay loop muted id="qnaReqCnt_4">
    <source src="@/assets/media/sound_4.mp3" type="audio/wav" />
  </audio>
  <audio ref="qnaReqCnt_5" autoplay loop muted id="qnaReqCnt_5">
    <source src="@/assets/media/sound_5.mp3" type="audio/wav" />
  </audio>

  <audio ref="cashInReqCnt_1" autoplay loop muted id="cashInReqCnt_1">
    <source src="@/assets/media/sound_1.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashInReqCnt_2" autoplay loop muted id="cashInReqCnt_2">
    <source src="@/assets/media/sound_2.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashInReqCnt_3" autoplay loop muted id="cashInReqCnt_3">
    <source src="@/assets/media/sound_3.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashInReqCnt_4" autoplay loop muted id="cashInReqCnt_4">
    <source src="@/assets/media/sound_4.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashInReqCnt_5" autoplay loop muted id="cashInReqCnt_5">
    <source src="@/assets/media/sound_5.mp3" type="audio/wav" />
  </audio>

  <audio ref="cashOutReqCnt_1" autoplay loop muted id="cashOutReqCnt_1">
    <source src="@/assets/media/sound_1.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashOutReqCnt_2" autoplay loop muted id="cashOutReqCnt_2">
    <source src="@/assets/media/sound_2.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashOutReqCnt_3" autoplay loop muted id="cashOutReqCnt_3">
    <source src="@/assets/media/sound_3.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashOutReqCnt_4" autoplay loop muted id="cashOutReqCnt_4">
    <source src="@/assets/media/sound_4.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashOutReqCnt_5" autoplay loop muted id="cashOutReqCnt_5">
    <source src="@/assets/media/sound_5.mp3" type="audio/wav" />
  </audio>

  <audio ref="memJoinReqCnt_1" autoplay loop muted id="memJoinReqCnt_1">
    <source src="@/assets/media/sound_1.mp3" type="audio/wav" />
  </audio>
  <audio ref="memJoinReqCnt_2" autoplay loop muted id="memJoinReqCnt_2">
    <source src="@/assets/media/sound_2.mp3" type="audio/wav" />
  </audio>
  <audio ref="memJoinReqCnt_3" autoplay loop muted id="memJoinReqCnt_3">
    <source src="@/assets/media/sound_3.mp3" type="audio/wav" />
  </audio>
  <audio ref="memJoinReqCnt_4" autoplay loop muted id="memJoinReqCnt_4">
    <source src="@/assets/media/sound_4.mp3" type="audio/wav" />
  </audio>
  <audio ref="memJoinReqCnt_5" autoplay loop muted id="memJoinReqCnt_5">
    <source src="@/assets/media/sound_5.mp3" type="audio/wav" />
  </audio>

  <audio ref="dangerBet_casino" id="dangerBet_casino" autoplay loop muted>
    <source src="@/assets/media/sound_danger.wav" type="audio/wav" />
  </audio>
  <audio ref="overBet_casino" id="overBet_casino" autoplay loop muted>
    <source src="@/assets/media/sound_overbet.wav" type="audio/wav" />
  </audio>

  <audio ref="dangerBet_slot" id="dangerBet_slot" autoplay loop muted>
    <source src="@/assets/media/sound_danger.wav" type="audio/wav" />
  </audio>
  <audio ref="overBet_slot" id="overBet_slot" autoplay loop muted>
    <source src="@/assets/media/sound_overbet.wav" type="audio/wav" />
  </audio>

  <audio ref="dangerBet_sports" id="dangerBet_sports" autoplay loop muted>
    <source src="@/assets/media/sound_danger.wav" type="audio/wav" />
  </audio>
  <audio ref="overBet_sports" id="overBet_sports" autoplay loop muted>
    <source src="@/assets/media/sound_overbet.wav" type="audio/wav" />
  </audio>

  <audio ref="dangerBet_minigame" id="dangerBet_minigame" autoplay loop muted>
    <source src="@/assets/media/sound_danger.wav" type="audio/wav" />
  </audio>
  <audio ref="overBet_minigame" id="overBet_minigame" autoplay loop muted>
    <source src="@/assets/media/sound_overbet.wav" type="audio/wav" />
  </audio>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'NotificationAlarm',
  props: ['headInfo'],
  data() {
    return {
      audio: {}
    };
  },
  mounted() {
    let AudioContext;
    // eslint-disable-next-line no-unused-vars
    let audioContext;

    window.onload = function () {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContext = new AudioContext();
        })
        .catch(e => {
          console.error(`Audio permissions denied: ${e}`);
        });
    };
  },
  watch: {
    alarmInfo: {
      handler(model, oldModel) {
        if (!oldModel) return;
        // const bellKeys = ["memberApply", "inApply", "outApply", "boardCnt", "biSummaryApplyCnt"];
        // const bellKeys = ['qnaReqCnt', 'cashInReqCnt', 'cashOutReqCnt', 'memJoinReqCnt', 'insuranceCnt', 'insuranceExchangeCnt', 'creditCnt', 'creditExchangeCnt'];
        const bellKeys = this.alarmSoundSetting;
        // if(bellKeys && bellKeys.length !== 0) {
        //   bellKeys.forEach(key => {
        //     if (this.$refs[key]) {
        //       this.$refs[key].muted = true;
        //       if (model[key] && model[key] > 0) {
        //         this.$refs[key].muted = false;
        //         const audio = document.querySelector('#' + key).play();
        //         if (audio !== undefined) {
        //           audio.then(_ => {}).catch(() => {});
        //         }
        //       }
        //     }
        //   });
        // } else {
        //   console.log(bellKeys)
        // }
        if (bellKeys && bellKeys.length !== 0) {
          bellKeys.forEach(item => {
            if (this.$refs[item.sound]) {
              this.$refs[item.sound].muted = true;
              if (model[item.type] && model[item.type] > 0) {
                this.$refs[item.sound].muted = false;
                // console.log(`MAX UP NOISE:[${item.sound}]`);
                const audio = document.querySelector('#' + item.sound).play();
                if (audio !== undefined) {
                  audio
                    .then(_ => {})
                    .catch(e => {
                      console.log(e);
                    });
                }
              }
            }
          });
        } else {
          // console.log(bellKeys);
        }
      },
      deep: true,
      immediate: true
    },
    dangerBetCount: {
      handler: function (item) {
        // console.log(item);
        if (!item) return;
        Object.entries(item).forEach(([key, value]) => {
          if (value > 0) {
            if (this.$refs[`dangerBet_${key}`]) {
              this.$refs[`dangerBet_${key}`].muted = false;
              const audio = document.querySelector(`#dangerBet_${key}`).play();
              if (audio !== undefined) {
                audio.then(_ => {}).catch(() => {});
              }
            }
          } else {
            if (this.$refs[`dangerBet_${key}`]) {
              this.$refs[`dangerBet_${key}`].muted = true;
            }
          }
        });
      },
      deep: true
    },
    overBetCount: {
      handler(item) {
        if (!item) return;
        Object.entries(item).forEach(([key, value]) => {
          if (value > 0) {
            if (this.$refs[`overBet_${key}`]) {
              this.$refs[`overBet_${key}`].muted = false;
              const audio = document.querySelector(`#overBet_${key}`).play();
              if (audio !== undefined) {
                audio.then(_ => {}).catch(() => {});
              }
            }
          } else {
            if (this.$refs[`overBet_${key}`]) {
              this.$refs[`overBet_${key}`].muted = true;
            }
          }
        });
      },
      deep: true
    },
    siteOptions: {
      handler(value) {
        // console.log(value);
      }
    }
  },
  computed: {
    ...mapState(['alarmInfo', 'dangerBetCount', 'overBetCount']),
    ...mapGetters(['headCashInfo', 'siteOptions']),
    alarmSoundSetting() {
      try {
        const settings = this.siteOptions.alarmSounds;
        if (settings && settings.length !== 0) {
          const splitItem = settings.split(',');
          return [
            {
              type: 'qnaReqCnt',
              sound: `qnaReqCnt_${splitItem[0]}`
            },
            {
              type: 'cashInReqCnt',
              sound: `cashInReqCnt_${splitItem[1]}`
            },
            {
              type: 'cashOutReqCnt',
              sound: `cashOutReqCnt_${splitItem[2]}`
            },
            {
              type: 'memJoinReqCnt',
              sound: `memJoinReqCnt_${splitItem[3]}`
            }
          ];
        } else {
          return [];
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    }
  }
};
</script>

<style scoped>
.notification-container {
  display: flex;
  align-items: center;
  width: calc(100% - 220px);
  min-width: 1440px;
  justify-content: space-between;
  padding-right: 10px;
  box-sizing: border-box;
  margin: 10px 20px 10px 10px;
}
.notification-wrap {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
}
.noti-title {
  font-size: 13px;
  color: #ededed;
  font-weight: 800;
  margin-right: 10px;
}
.noti-title span {
  white-space: nowrap;
}
.noti-title span:last-child {
  margin-left: 5px;
}
.noti-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 6px;
  height: 25px;
  border-radius: 3px;
  color: #666;
  margin-right: 3px;
  user-select: none;
  border: 1px solid #bfbfbf;
}
.noti-item a {
  color: #666;
}
.noti-item a span span {
  color: #e95d50;
  font-weight: bold;
}
.noti-item a:first-child span span {
  color: #18698b;
}
.noti-item .text {
  margin-right: 2px;
}
.title-box {
  margin-right: 10px;
}
.contents-box {
  margin-right: 6px;
}
.contents-box.title {
  font-weight: 700;
}
.contents-box:last-child {
  margin: 0;
}

.content-mono {
  margin-right: 4px;
}
a {
  color: #fff;
}
a:hover {
  font-weight: 800;
}
</style>
