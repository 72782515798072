<template>
  <transition>
    <nav>
      <div class="nav_header">
        <div class="logo_img">
          <img src="../../assets/img/nature_logo.png" />
        </div>
        <div class="admin_info">
          <span>{{ $t('nav.chooseLang') }}</span>
          <div class="langWrap" @mouseover="showMenuLang = true" @mouseleave="showMenuLang = false">
            <button class="langT">
              <span class="" v-if="$i18n.locale === 'ko'"
                ><img src="@/assets/img/kr.svg" />
                <!-- <span>KR</span> -->
              </span>
              <span class="" v-if="$i18n.locale === 'en'"
                ><img src="@/assets/img/us.svg" />
                <!-- <span>EN</span> -->
              </span>
              <span class="" v-if="$i18n.locale === 'cn'"
                ><img src="@/assets/img/cn.svg" />
                <!-- <span>KR</span> -->
              </span>
              <span class="" v-if="$i18n.locale === 'th'"
                ><img src="@/assets/img/th.svg" />
                <!-- <span>EN</span> -->
              </span>
              <span class="" v-if="$i18n.locale === 'jp'"
                ><img src="@/assets/img/jp.svg" />
                <!-- <span>EN</span> -->
              </span>
              <span class="" v-if="$i18n.locale === 'vn'"
                ><img src="@/assets/img/vn.svg" />
                <!-- <span>EN</span> -->
              </span>
            </button>
            <div class="langB" v-if="showMenuLang">
              <a href="#" :class="{ on: $i18n.locale === 'ko' }" @click="changeLocale('ko')"><img src="@/assets/img/kr.svg" alt="KO" /></a>
              <a href="#" :class="{ on: $i18n.locale === 'en' }" @click="changeLocale('en')"><img src="@/assets/img/us.svg" alt="EN" /></a>
              <a href="#" :class="{ on: $i18n.locale === 'cn' }" @click="changeLocale('cn')"><img src="@/assets/img/cn.svg" alt="CN" /></a>
              <a href="#" :class="{ on: $i18n.locale === 'ti' }" @click="changeLocale('th')"><img src="@/assets/img/th.svg" alt="TH" /></a>
              <a href="#" :class="{ on: $i18n.locale === 'jp' }" @click="changeLocale('jp')"><img src="@/assets/img/jp.svg" alt="JP" /></a>
              <a href="#" :class="{ on: $i18n.locale === 'vn' }" @click="changeLocale('vn')"><img src="@/assets/img/vn.svg" alt="VN" class="vnimg" /></a>
            </div>
            <!-- <button class="btn btn-sm btn-primary mr-15" type="button" @click="signOut">LOGOUT</button> -->
          </div>
        </div>
        <div class="admin_info">
          <span>{{ $t('common.userId') }}</span>
          <span>{{ siteInfo.adminId }}</span>
        </div>
        <div class="reserves">
          <template v-if="creditBalance && creditBalance.length !== 0">
            <div class="holding_money" v-for="item in creditBalance" :key="item.code">
              <span>{{ (item.creditType === 'mega' ? 'sports' : item.creditType).toUpperCase() }}</span>
              <!-- <span>{{ numberWithCommas(alarmInfo[item.vendorCreditKeyword]) }}</span> -->
              <span>{{ numberWithCommas(item.creditAmt || 0) }}</span>
            </div>
          </template>
          <p class="noti-item">* {{ $t('common.navNotice') }}</p>
        </div>
        <!-- <div class="btn-credit-wrap fx fx-center-all gap-10 logOut_btn">
          <button class="btn btn-sm btn-primary apply" type="button" @click="modalOpen('apply')">크레딧 신청</button>
          <button class="btn btn-sm btn-primary exchange" type="button" @click="modalOpen('exchange')">크레딧 환전</button>
        </div> -->
        <div class="logOut_btn">
          <button data-v-3a1d3450="" class="btn btn-sm btn-primary logout" @click="signOut" type="button">LOGOUT</button>
        </div>
      </div>
      <notification-alarm :headInfo="headCashInfo" />
      <ul class="menu-wrap nav-bottom" v-if="isLoaded">
        <template v-for="menu in menuList" :key="menu.name">
          <li v-if="menu.exceptType !== siteInfo.partnerType">
            <ul class="content-wrap" :class="'active'">
              <li class="menu-title" @click="menu.isActive = !menu.isActive">
                <div class="text-wrap">
                  <!-- <i :class="menu.icon" /> -->
                  <span class="text">
                    <template v-if="menu.i18n">
                      {{ $t(menu.i18n) }}
                    </template>
                    <template v-else>
                      {{ menu.text }}
                    </template>
                  </span>
                  <!-- $t(`table.head.${text}`) -->
                </div>
              </li>
              <li class="menu-contents">
                <ul>
                  <template v-for="child in menu.children">
                    <li :key="child.path" v-if="child.meta.menuMember && child.meta.exceptType !== siteInfo.partnerType">
                      <router-link class="supMenu" :to="child.path" v-if="!child.meta.group || (child.meta.group && usedGameGroup.includes(child.meta.group))">
                        <span>
                          <template v-if="child.meta.i18n">
                            {{ $t(child.meta.i18n) }}
                          </template>
                          <template v-else>
                            {{ child.meta.text }}
                          </template>
                        </span>
                      </router-link>
                    </li>
                  </template>
                </ul>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </nav>
  </transition>
  <div class="modalWrap" v-if="modalActive">
    <div class="modal-item">
      <div class="modal-title">
        <div v-if="modalType === 'apply'">
          <ul style="display: flex; cursor: pointer" class="PTtabmenu">
            <li class="p5-10" data-tab="tab-1" @click="currentTab = 'PTtab-1'" :class="{ current: currentTab === 'PTtab-1' }">
              <h3>크레딧 신청</h3>
            </li>
            <li class="p5-10" data-tab="tab-2" @click="currentTab = 'PTtab-2'" :class="{ current: currentTab === 'PTtab-2' }">
              <h3>포인트 사용</h3>
            </li>
          </ul>
        </div>
        <div v-if="modalType === 'exchange'" class="ftB p5-10" style="display: flex; justify-content: center; color: #fff">크레딧 환전</div>
      </div>
      <div class="modal-body">
        <article class="fx-item-auto fx gap-5">
          <div class="item-title">보유 크레딧</div>
          <input type="text" :value="numberWithCommas(modalData.creditAmt)" disabled />
        </article>
        <article class="fx-item-auto fx gap-5" v-if="modalType === 'apply'" :class="{ nomarginbt: currentTab === 'PTtab-1' }">
          <div class="item-title" v-if="currentTab === 'PTtab-2'">보유 크레딧포인트</div>
          <input v-if="currentTab === 'PTtab-2'" type="text" :value="numberWithCommas(modalData.creditSubPoint)" disabled />
          <!-- <div v-if="modalType === 'apply'">
            <select v-if="currentTab === 'PTtab-2'" v-model="creditApplyData.creditType">
              <option :value="'2'" :selected="true">{{ $t('common.credit') }}</option>
              <option :value="'3'">CASH</option>
            </select>
          </div> -->
        </article>
        <article class="fx-item-auto fx gap-5">
          <div class="item-title" v-if="modalType === 'apply'">
            <p id="PTtab-1" :key="'PTtab-1'" v-if="currentTab === 'PTtab-1'">신청 크레딧</p>
          </div>
          <div class="item-title" v-if="modalType === 'exchange'">환전 PT</div>
          <input type="text" v-if="modalType === 'exchange' || currentTab === 'PTtab-1'" maxlength="15" ref="creditApplyInput" :value="numberWithCommas(creditApplyAmt)" @input="setItem($event, 'creditApplyAmt')" />
        </article>
      </div>
      <div class="btn-wrap">
        <button class="btn-layout btn-blue" type="button" @click="creditApply" v-if="modalType === 'apply'">신청</button>
        <button class="btn-layout btn-blue" type="button" @click="creditExchange" v-if="modalType === 'exchange'">환전신청</button>
        <button class="btn-layout btn-blue" type="button" @click="modalClose">{{ $t('button.close') }}</button>
      </div>
    </div>
  </div>
  <div class="modalWrap" v-if="insuredModal.active">
    <div class="modal-item modal2">
      <article class="modal-title">
        <sub-title :text="`보험금 신청`" v-if="insuredModal.type === 'apply'" />
        <sub-title :text="`보험금 환전`" v-if="insuredModal.type === 'exchange'" />
      </article>
      <div class="modal-body">
        <article class="fx-item-auto fx gap-5">
          <div class="item-title">보유 보험금</div>
          <input type="text" :value="numberWithCommas(insuredModal.insuredAmt)" disabled />
        </article>
        <article class="fx-item-auto fx gap-5" v-if="insuredModal.type === 'apply'">
          <div class="item-title">보유 서브포인트</div>
          <input v-if="!useBiSubPointYn" type="text" :value="numberWithCommas(insuredModal.insuredSubPoint)" disabled />
          <input v-else type="text" :value="0" disabled />
        </article>
        <article class="fx-item-auto fx gap-5">
          <div class="item-title" v-if="insuredModal.type === 'apply'">신청 보험금</div>
          <div class="item-title" v-if="insuredModal.type === 'exchange'">환전 보험금</div>
          <input type="text" ref="biApplyInput" maxlength="15" :value="numberWithCommas(biApplyData.insuredAmt)" @input="setItem($event, biApplyData, 'insuredAmt')" />
        </article>
        <article class="fx-item-auto fx gap-5" v-if="insuredModal.type === 'apply'">
          <div class="item-title">서브포인트 사용여부</div>
          <ui-check :id="`useSubPoint`" :checked="useBiSubPointYn" @change="setUseBiSubPointYn(useBiSubPointYn)" />
          <span class="modalNotice" v-if="useBiSubPointYn">사용한 서브포인트 : {{ numberWithCommas(insuredModal.insuredSubPoint) }}</span>
        </article>
      </div>
      <div class="btn-wrap">
        <button class="btn-layout btn-blue" type="button" @click="applyInsured" v-if="insuredModal.type === 'apply'">지급신청</button>
        <button class="btn-layout btn-blue" type="button" @click="exchangeInsured" v-if="insuredModal.type === 'exchange'">환전신청</button>
        <button class="btn-layout btn-blue" type="button" @click="modalClose2">{{ $t('button.close') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';
import { removeAuthData, getSiteData, setLangCookie } from '@/libs/auth-helper';

import { companyDetail, creditChargeApply, creditChargeApplySubPoint, creditWithdrawApply } from '@/api/member';
import { insuredApply } from '@/api/insured';
import { mapGetters, mapMutations, mapState } from 'vuex';
import subTitle from '@/components/main/PageSubTitle';
import UiCheck from '@/components/ui/UiCheck';
import NotificationAlarm from '@/components/main/header/NotificationAlarm';
// import store from '@/store';
import { numberWithCommas } from '@/libs/utils';
import store from '@/store';

export default {
  name: 'SiteMenu',
  components: {
    subTitle,
    UiCheck,
    NotificationAlarm
  },
  data() {
    return {
      useBiSubPointYn: false,
      showMenuLang: false,
      currentTab: 'PTtab-1',
      items: [],
      loginPath: '/login',
      siteInfo: {},
      menuList: [],
      currentMenu: '',
      creditApplyData: {
        creditType: '2',
        creditAmt: '0'
      },
      biApplyData: {
        siteId: '',
        insuredType: '1',
        insuredAmt: '0',
        insuredStatus: '0'
      },
      cashInfo: {},
      currentSubMenu: '',
      creditAmt: 0,
      creditSubPoint: 0,
      biAmt: 0,
      insuredSubPoint: 0,
      useSubPointYn: false,
      modalType: '',
      modalActive: false,
      creditApplyAmt: 0,
      modalData: {
        creditAmt: 0,
        creditSubPoint: 0
      },
      insuredModal: {
        active: false,
        type: '',
        insuredAmt: 100,
        insuredSubPoint: 0
      },
      preCreditSubPoint: 0,
      LV000001: false,
      LV000002: false,
      isLoaded: false
    };
  },
  methods: {
    numberWithCommas,
    changeLocale(lang) {
      if (this.$i18n.locale === lang) {
        return;
      } else {
        this.$i18n.locale = lang;
      }
      setLangCookie(lang);
      this.showMenuLang = false;
      location.reload();
    },
    goToPageName(value) {
      this.$router.push(value);
    },
    setItem(e, item, key) {
      this.onlyNumber(e);
      if (this.insuredModal.active) {
        if (this.insuredModal.type === 'exchange') {
          if (this.deleteCommas(e.target.value) > Number(this.insuredModal.insuredAmt)) {
            e.target.value = this.numberWithCommas(lodash.cloneDeep(this.insuredModal.insuredAmt));
          }
        }
      } else if (this.modalActive) {
        if (this.modalType === 'exchange') {
          if (this.deleteCommas(e.target.value) > Number(this.modalData.creditAmt)) {
            e.target.value = this.numberWithCommas(lodash.cloneDeep(this.modalData.creditAmt));
          }
        }
      }
      if (key) {
        item[key] = this.deleteCommas(e.target.value);
      } else {
        this[item] = this.deleteCommas(e.target.value);
      }
    },
    setUseSubPointYn(item) {
      this.useSubPointYn = !item;
    },
    setUseBiSubPointYn(item) {
      this.useBiSubPointYn = !item;
    },
    modalOpen(type) {
      this.modalType = type;
      this.modalData.creditAmt = lodash.cloneDeep(this.alarmInfo.creditAmt);
      this.modalData.creditSubPoint = lodash.cloneDeep(this.alarmInfo.creditSubPoint);
      this.modalActive = true;
    },
    modalOpen2(type) {
      this.insuredModal.insuredAmt = lodash.cloneDeep(this.alarmInfo.insuredAmt);
      this.insuredModal.insuredSubPoint = lodash.cloneDeep(this.alarmInfo.insuredSubPoint);
      this.insuredModal.type = type;
      this.insuredModal.active = true;
    },
    modalClose() {
      this.creditApplyAmt = 0;
      this.modalActive = false;
    },
    modalClose2() {
      this.insuredModal.type = '';
      this.insuredModal.active = false;
      this.creditApplyAmt = 0;
    },
    async creditApply() {
      const creditAmt = this.creditApplyAmt;
      if (this.currentTab === 'PTtab-1') {
        if (creditAmt === null || creditAmt === undefined) {
          return;
        } else if (creditAmt <= 0) {
          alert('최소 충전 PT은 0 이상이어야 합니다.');
          return;
        } else if (isNaN(creditAmt)) {
          alert('숫자만 작성해주세요.');
          return;
        }
      }

      const param = {};

      if (this.currentTab === 'PTtab-1') {
        param.creditAmt = creditAmt;
      }

      if (this.currentTab === 'PTtab-2') {
        param.creditType = this.creditApplyData.creditType;
      }

      console.log(param);

      const trigger = confirm(`${creditAmt}PT을 요청하시겠습니까?`);
      if (trigger) {
        let result;
        if (this.currentTab === 'PTtab-1') {
          result = await creditChargeApply(param);
        } else {
          result = await creditChargeApplySubPoint(param);
        }

        if (result.resultCode === '200') {
          alert('PT 신청완료');
          this.modalClose();
        } else {
          alert('PT 요청 실패, 다시 시도해주세요.');
        }
      }
      this.creditApplyData.creditAmt = 0;
    },
    async creditExchange() {
      const creditAmt = this.creditApplyAmt;
      // console.log(creditAmt);
      if (creditAmt === null || creditAmt === undefined) {
        return;
      } else if (creditAmt <= 0) {
        alert('최소 환전 PT은 0 이상이어야 합니다.');
        return;
      } else if (isNaN(creditAmt)) {
        alert('숫자만 작성해주세요.');
        return;
      }
      this.creditApplyData.creditType = '-1';
      this.creditApplyData.creditAmt = creditAmt;
      const trigger = confirm(`${creditAmt}PT를 환전합니다.`);
      if (trigger) {
        // eslint-disable-next-line no-undef
        const result = await creditWithdrawApply(this.creditApplyData);
        // console.log(result);
        if (result.resultCode === '200') {
          alert('PT 환전신청완료');
          this.modalClose();
        } else {
          alert('PT 환전 요청 실패, 다시 시도해주세요.');
        }
      }
      this.creditApplyData.creditAmt = 0;
    },
    async applyInsured() {
      const biAmt = this.biApplyData;
      biAmt.siteId = this.siteInfo.siteId;
      if (this.useBiSubPointYn) {
        biAmt.insuredType = '2';
      } else {
        biAmt.insuredType = '1';
      }
      if (biAmt.insuredAmt === null || biAmt.insuredAmt === undefined) {
        return;
      } else if (biAmt.insuredAmt <= 0) {
        alert('최소 지급신청 보험금은 0 이상이어야 합니다.');
        return;
      } else if (isNaN(biAmt.insuredAmt)) {
        alert('숫자만 작성해주세요.');
        return;
      }
      const trigger = confirm(`${this.numberWithCommas(biAmt.insuredAmt)}보험금을 충전합니다.`);
      if (trigger) {
        const result = await insuredApply(biAmt);
        // console.log(result);
        if (result.resultCode === '200') {
          alert('보험금 지급 신청완료');
          this.modalClose2();
        } else {
          alert('보험금 지급 요청 실패, 다시 시도해주세요.');
        }
      }
      this.biApplyData.insuredAmt = 0;
    },
    async exchangeInsured() {
      const biAmt = this.biApplyData;
      biAmt.siteId = this.siteInfo.siteId;
      biAmt.insuredType = '-1';
      if (biAmt.insuredAmt === null || biAmt.insuredAmt === undefined) {
        return;
      } else if (biAmt.insuredAmt <= 0) {
        alert('최소 환전신청 보험금은 0 이상이어야 합니다.');
        return;
      } else if (isNaN(biAmt.insuredAmt)) {
        alert('숫자만 작성해주세요.');
        return;
      }
      const trigger = confirm(`${this.numberWithCommas(biAmt.insuredAmt)}보험금을 환전합니다.`);
      if (trigger) {
        const result = await insuredApply(biAmt);
        // console.log(result);
        if (result.resultCode === '200') {
          alert('보험금 환전 신청완료');
          this.modalClose2();
        } else {
          alert('보험금 환전 요청 실패, 다시 시도해주세요.');
        }
      }
      this.biApplyData.insuredAmt = 0;
    },
    getSiteName() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    },
    async getUpperCompany() {
      const siteId = this.siteInfo.siteId;
      const reqDetail = {
        siteId,
        memId: siteId
      };
      const loginCompanyDetail = await companyDetail(reqDetail);
      // console.log(loginCompanyDetail);
      const member = loginCompanyDetail.data.memberDetail;
      if (!member.partnerType) {
        this.siteInfo.partnerType = member.partnerType;
      } else {
        this.siteInfo.partnerType = member.partnerType;
      }
      if (!member.recommenderId) {
        this.siteInfo.recommenderId = 'siteOperator';
      } else {
        this.siteInfo.recommenderId = member.recommenderId;
      }
      if (!member.memName) {
        this.siteInfo.siteName = member.memId;
      } else {
        this.siteInfo.siteName = member.memName;
      }
      this.siteInfo.creditRate = member.creditRate;
    },
    ...mapMutations(['setErrorData', 'clearAllSiteData']),
    signOut() {
      this.clearAllSiteData();
      removeAuthData();

      location.href = '/login';
    },
    async setMenuList() {
      this.isLoaded = false;

      const locale = this.$i18n.locale;

      setTimeout(() => {
        this.menuList = [];
        const menuObj = this.menuData;

        // if (menuObj) {
        const items = this.$router.getRoutes();

        // const partnerType = this.siteInfo.partnerType;
        for (const item of items) {
          const routeName = item.name;

          if (routeName === 'member') {
            for (const mItem of item.children) {
              // const code = mItem.meta.code;
              // console.log(mItem)

              const _true = true;
              if (_true) {
                // if (code && menuObj[code]) {
                const model = {
                  name: mItem.name,
                  path: mItem.path,
                  // text: locale === "ko" ? menuObj[code].menuCodeName : menuObj[code]["menuCodeName" + locale.toUpperCase()],
                  i18n: mItem.meta.i18n,
                  text: mItem.meta.text,
                  icon: mItem.meta.icon,
                  isActive: mItem.meta.isActive,
                  exceptType: mItem.meta.exceptType || false
                };
                mItem.meta.text = model.text;
                const children = mItem.children;
                const realChildren = [];
                for (const cItem of children) {
                  // const cCode = cItem.meta.code;
                  // if (cCode && menuObj[cCode] && cItem.meta.menuMember) {
                  // cItem.meta.text = locale === "ko" ? menuObj[cCode].menuCodeName : menuObj[cCode]["menuCodeName" + locale.toUpperCase()];
                  const subChildren = cItem.children;
                  const subList = [];
                  if (subChildren) {
                    for (const subItem of subChildren) {
                      // const subCode = subItem.meta.code;
                      // if (subCode && menuObj[subCode]) {
                      //   subItem.meta.text = locale === 'ko' ? menuObj[subCode].menuCodeName : menuObj[subCode]['menuCodeName' + locale.toUpperCase()];
                      if (subItem.group) {
                        if (subItem.group.includes(this.gameGroupCodes)) {
                          subList.push(subItem);
                        }
                      } else {
                        subList.push(subItem);
                      }
                      // }
                    }
                    cItem.children = subList;
                  }
                  if (cItem.group) {
                    if (cItem.group.includes(this.gameGroupCodes)) {
                      subList.push(cItem);
                    }
                  } else {
                    subList.push(cItem);
                  }
                  realChildren.push(cItem);
                  // }
                }

                model.children = realChildren;

                this.menuList.push(model);
              }
            }
          }
          // }
          this.getCurrentMenu();
        }
      }, 0);
    },
    getCurrentMenu() {
      this.currentMenu = this.$route.matched[1];
      this.currentSubMenu = this.$route.matched[2];
      this.currentGrandSubMenu = this.$route.matched[3];
      // for (const menu of this.menuList) {
      //   if (menu.name === this.currentMenu.name) {
      //     menu.isActive = true;
      //     for (const subMenu of menu.children) {
      //       if (subMenu.name === this.currentSubMenu.name) {
      //         subMenu.meta.isActive = true;
      //       } else {
      //         subMenu.meta.isActive = false;
      //       }
      //       if (this.currentGrandSubMenu) {
      //         if (subMenu.children) {
      //           for (const grandChild of subMenu.children) {
      //             if (grandChild.name === this.currentGrandSubMenu.name) {
      //               if (grandChild.name.indexOf('BetList') > -1) {
      //                 const gameId_router = this.$route.params.id;
      //                 const menuPath = grandChild.path.split('/');
      //                 const gameId_menu = menuPath[4];
      //
      //                 if (gameId_router === gameId_menu) {
      //                   grandChild.meta.isActive = true;
      //                 } else {
      //                   grandChild.meta.isActive = false;
      //                 }
      //               } else {
      //                 grandChild.meta.isActive = true;
      //               }
      //             } else {
      //               grandChild.meta.isActive = false;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   } else {
      //     menu.isActive = false;
      //   }
      // }
      this.isLoaded = true;
    }
  },
  watch: {
    $i18n() {
      this.setMenuList();
    },
    $route: {
      handler() {
        // console.log('pageTitle : ', this.$route)
        if (this.$route.fullPath !== '/login') {
          this.setMenuList();
          this.getCurrentMenu();
        }
      }
    },
    gameList: {
      async handler(value) {
        await this.getUpperCompany();
        await this.setMenuList();
      }
    },
    providers: {
      handler(value) {
        // console.log(value);
      },
      immediate: true
    },
    useSubPointYn: {
      handler(checked) {
        if (checked) {
          if (Number(this.siteInfo.creditRate) !== 0) {
            this.creditApplyAmt = Math.ceil((Number(this.modalData.creditSubPoint) / Number(this.siteInfo.creditRate)) * 100);
          } else {
            this.creditApplyAmt = 0;
          }
          this.preCreditSubPoint = lodash.cloneDeep(this.modalData.creditSubPoint);
          this.modalData.creditPointAmt = 0;
          this.$refs.creditApplyInput.setAttribute('disabled', true);
        } else {
          this.modalData.creditPointAmt = lodash.cloneDeep(this.creditPointAmt);
          this.creditApplyAmt = 0;

          this.$refs.creditApplyInput.removeAttribute('disabled');
        }
      }
    },
    menuData(state) {
      this.setMenuList();
    },
    useBiSubPointYn(yn) {
      if (yn) {
        this.$refs.biApplyInput.setAttribute('readonly', true);
        this.creditApplyAmt = lodash.cloneDeep(this.alarmInfo.insuredSubPoint);
        this.biApplyData.insuredAmt = this.creditApplyAmt;
      } else {
        this.$refs.biApplyInput.removeAttribute('readonly');
        this.creditApplyAmt = 0;
        this.biApplyData.insuredAmt = 0;
      }
    }
  },
  computed: {
    ...mapGetters(['gameList', 'headCashInfo', 'providers', 'gameGroupCodes', 'creditBalance']),
    ...mapState(['menuData', 'isPT', 'isInsured', 'alarmInfo']),
    usedGameGroup() {
      return this.gameGroupCodes.reduce((result, item) => {
        result.push(item.groupCode);
        return result;
      }, []);
    }
  },
  async created() {
    await store.dispatch('storeProviders');
    await this.setMenuList();
    this.siteInfo = this.getSiteName();
    // const res = await headerCashInfo();
    // if (res.resultCode === '200') {
    //   store.commit("setAlarmInfo", res.data.headCashInfo);
    // }
    await this.getUpperCompany();
    await this.getCurrentMenu();
  }
};
</script>

<style scoped>
nav {
  width: 220px;
  min-height: calc(100vh - 50px);
  /* padding-bottom: 40px; */
  background: #353535;
  min-width: 220px;
  overflow-y: scroll;
}

.header-lower {
  background: #e1534e;
  color: #fff;
  margin: 0 10px 0;
  font-size: 12px;
}

.header-lower .item {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 20px;
}

.nav-upper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 10px 0;
}

.user-info-wrap.credit-wrap {
  margin: 3px 0 !important;
  background: #fff;
  border-top: 3px solid #e1534e;
  border-bottom: 3px solid #e1534e;
}

.user-info-wrap.top {
  background: #fff;
  color: #353535;
  font-size: 14px;
  padding: 10px 15px;
}

.user-info-wrap {
  box-sizing: border-box;
  width: 100%;
}

.user-info-wrap:last-child {
  margin: 0;
}

.user-info-wrap .contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mId {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logout-name {
  font-size: 16px;
  font-size: 16px;
  font-weight: bold;
}

.logout-link {
  background: none;
  cursor: pointer;
  margin-left: 10px;
}

.logout-link img {
  vertical-align: middle;
}

.logout-link:hover {
  /*background: #000;*/
  color: #fff;
}

.img-profile {
  width: 38px;
  border-radius: 100%;
  margin-right: 8px;
}

.wopen-note {
  color: #fff;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  font-size: 0.8em;
  cursor: pointer;
  user-select: none;
}

.wopen-note img {
  width: 26px;
  margin-bottom: 6px;
}

.notification-wrap {
  margin: 0 10px 3px;
  background: #fff;
  border-top: 3px solid #e1534e;
  border-bottom: 3px solid #e1534e;
  padding: 10px 15px;
  font-size: 13px;
}

.notification-wrap .noti-item:first-child {
  margin-top: 0;
}

.notification-wrap .noti-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
}

.notification-wrap .noti-item h4 {
  background: #353535;
  font-size: 14px;
  color: #fff;
  width: 64px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.notification-wrap .noti-item a:last-child,
.notification-wrap .noti-item div:last-child {
  margin-left: auto;
}

.notification-wrap .noti-item a span span {
  color: #0f84ee;
  font-weight: bold;
}

.notification-wrap .noti-item a:last-child span span {
  color: #d93832;
}

.nav-bottom {
  /* margin: 0 10px 28px; */
  background: #fff;
  min-height: 100%;
  /* border-top: 5px solid #e1534e;
  border-bottom: 5px solid #e1534e; */
  font-size: 16px;
}

.nav-bottom > li:last-child .content-wrap.active > .menu-contents {
  border-bottom: 0;
}

nav .content-wrap {
  /* border-bottom: 1px solid #e1e0e0; */
  overflow: hidden;
}

nav .content-wrap.active {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu-title {
  color: rgb(255, 255, 255);
  font-size: 0.75em;
  background: #0c162d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 15px;
  font-weight: 600;
}

nav .content-wrap.active > .menu-title .fas {
  color: #d93832;
  transform: rotate(90deg);
}

.text-wrap .text {
  margin-left: 15px;
}

.fa-chevron-right {
  font-size: 13px;
  color: #a4a4a4;
}

.menu-contents {
  max-height: 0;
  overflow: hidden;
  font-size: 14px;
}

nav .content-wrap.active > .menu-contents {
  max-height: 1000px;
  /* overflow: unset; */
  transition: 0.5s;
  /* border-top: 2px solid #e1e0e0; */
  /* border-bottom: 5px solid #e1534e; */
}

.menu-contents li {
  position: relative;
  margin-bottom: 3px;
  word-break: keep-all;
  white-space: pre-line;
  background: #e7e7e7;
}

.menu-contents .supMenu i {
  padding-right: 15px;
  font-size: 10px;
}

.menu-contents .supMenu.active i {
  /* color: #d93832; */
  transform: rotate(90deg);
  padding: 30px 0 0;
}

.menu-contents .supMenu {
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 3px 0;
  font-size: 0.95em;
  align-items: center;
  padding-left: 20px;
  width: 100%;
  cursor: pointer;
  color: #000000;
  box-shadow: 0px 1px 1px #000000;
}

.menu-contents .supMenu:hover {
  /* color: #d93832; */
  background: #122a60;
  color: #fff;
}

.menu-contents .subMenu-wrap {
  max-height: 0;
  overflow: hidden;
  /* background-color: #b77c66; */
}

.menu-contents .subMenu {
  display: flex;
  height: 30px;
  align-items: center;
  font-weight: 300;
  color: #353535;
  width: 100%;
  box-sizing: border-box;
  padding-left: 60px;
}

.menu-contents .subMenu::before {
  content: '';
  display: block;
  width: 5px;
  height: 1px;
  margin-right: 10px;
  background-color: #18698b;
}

.menu-contents .subMenu:hover::before {
  background-color: #fff !important;
}

.menu-contents .subMenu-wrap.active {
  max-height: 630px;
}

.menu-contents .subMenu-container {
  flex-direction: column;
  align-items: flex-start;
}

.menu-contents a.router-link-exact-active,
.menu-contents a.router-link-active,
.menu-contents .supMenu.active,
.menu-contents .subMenu-title.active {
  /* color: #d93832; */
  /* background: #0072a5; */
  background: #122a60;
  color: #fff;
}

.menu-contents a.subMenu.router-link-exact-active::before,
.menu-contents a.subMenu.router-link-active::before,
.menu-contents a.subMenu.active::before {
  background: #d93832;
}

.menu-contents a.subMenu.router-link-exact-active,
.menu-contents a.subMenu.router-link-active,
.menu-contents a.subMenu.active {
  color: #d93832;
}

.menu-contents a.subMenu.router-link-exact-active:hover,
.menu-contents a.subMenu.router-link-active:hover,
.menu-contents .subMenu.active:hover {
  color: #d93832;
}

.btn-credit-wrap {
  margin-top: 5px;
}

.btn-credit-apply {
  font-size: 14px;
  box-sizing: border-box;
  background: #353535;
  color: #fff;
  padding: 3px 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.supMenu.done:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: green;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.credit-wrap {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;
}

.credit-wrap .title {
  font-weight: 800;
  /*color: #18698b;*/
  font-size: unset;
}

.credit-wrap .content-val {
  font-weight: 800;
  font-size: unset;
}

.tempBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tempBox-wrap.bi {
  border-top: 1px solid #a4a4a4;
  padding-top: 10px;
}

.tempBox-wrap {
  padding: 10px 15px;
  /*margin: 3px 0;
  background: #fff;
  border-top: 3px solid #e1534e;
  border-bottom: 3px solid #e1534e;
  padding: 10px 15px;*/
}

.tempBox-wrap > div {
  font-size: 16px;
}

.pt .tempBox {
  color: #9b8800;
}

.pt .tempBox.point {
  color: #7c4b00;
}

.bi .tempBox {
  color: #c437e7;
}

.bi .tempBox.point {
  color: #e737a9;
}

.tempBox-wrap .tempBox > div {
  margin-bottom: 0;
}

.tempBox.point > div {
  font-size: 14px;
}

.tempBox.point > div.content-val {
  font-weight: 500;
}

.btn-credit-apply {
  font-size: 14px;
  box-sizing: border-box;
  background: #353535;
  color: #fff;
  padding: 3px 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  flex: 1 1 0;
}

.btn-credit-apply:hover {
  font-weight: 700;
}

.modal-title {
  border-bottom: 2px solid #e1534e;
  padding: 5px 0;
  font-size: 18px;
  color: #adadad;
}

.modal-title .PTtabmenu {
  justify-content: center;
}

.modal-title .PTtabmenu li.current {
  font-weight: bold;
  box-sizing: border-box;
  color: #fff;
}

.sub-title {
  color: #fff;
  font-size: 18px;
  border-bottom: 0;
  justify-content: center;
}

.modal-item {
  width: 550px;
  margin: 0 auto;
  position: relative;
  margin-top: 350px;
  background: #353535;
  color: #fff;
  padding-bottom: 20px;
}

.modal-close {
  transform: translate(-50%, 100%);
}

.ui-check-wrap {
  width: fit-content;
  margin-right: 5px;
}

.btn-wrap {
  gap: 5px;
  width: 100%;
}

.modalWrap .item-title {
  padding: 5px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  min-width: 130px;
}

.modalWrap .item-title + input {
  text-align: right;
}

.modalWrap .fx-item-auto {
  width: fit-content;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.modalWrap .item-title::before {
  font-size: 10px;
  content: '●';
  color: #e1534e;
  margin-right: 7px;
}

.modal-body {
  padding: 20px 60px 10px;
}

.modal-body input {
  background: #353535;
  border: 1px solid #5a5a5a;
  color: #fff;
  font-size: 16px;
}

.modal-body select {
  background: #353535;
  border: 1px solid #5a5a5a;
  color: #fff;
  font-size: 16px;
}

.modalNotice {
  color: red;
  font-size: 0.8em;
  font-weight: 700;
}

.btn-layout {
  font-size: 16px;
  padding: 5px 10px;
  background: #e1534e;
}

.modal2 .modal-title {
  border-bottom: 2px solid #9460ce;
}

.modal2 .item-title::before {
  color: #9460ce;
}

.modal2 .btn-layout {
  background: #9460ce;
}

.nomarginbt {
  margin-bottom: 0 !important;
}

/*보유 서브포인트 탭1 에서 안보이게 ㅠ*/
.nav_header {
  background: #fff;
}

.nav_header .logo_img {
  display: flex;
  justify-content: center;
  padding: 21px 10px;
}

.nav_header .logo_img img {
  width: 190px;
}

.nav_header .admin_info {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  background: #f1f1f1;
  margin-bottom: 6px;
  box-shadow: 0px 1px 1px #6a6a6a;
  align-items: center;
  min-height: 30px;
  box-sizing: border-box;
}

.nav_header span {
  font-weight: 700;
  color: #454445;
  text-shadow: 1px 0px 0px #6a6a6a;
}

.nav_header .reserves {
  padding: 2px 10px;
  background: #f1f1f1;
  margin-bottom: 6px;
  box-shadow: 0px 1px 1px #6a6a6a;
}

.reserves .holding_money {
  display: flex;
  justify-content: space-between;
}

.reserves .holding_point {
  display: flex;
  justify-content: space-between;
}

.nav_header .logOut_btn {
  display: flex;
  justify-content: center;
  padding: 6px 10px;
  background: #f1f1f1;
}

.nav_header .logOut_btn button:hover {
  background-color: #122a60;
}

.nav_header .logOut_btn button {
  width: 100%;
  border: 3px #4f6eb7 outset;
}

.holding_money span:nth-child(2) {
  color: #ba2e29;
}

.holding_point span:nth-child(2) {
  color: #1a0ce1;
}

.noti-item {
  font-size: 12px;
  font-weight: 500;
  color: darkred;
}

.vnimg {
  width: 30px;
  height: 23px;
}

.langWrap {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.langWrap .langT {
  background: none;
}

.langWrap .langT > span {
  padding: 0 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
}

.langWrap .langB {
  border-radius: 5px;
  padding: 6px;
  position: absolute;
  top: 22px;
  border: 1px solid #53545f;
  background: #353535;
}

.langWrap .langB a {
  /* display: block; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 12px;
  margin-bottom: 6px;
}

.langWrap .langB a:first-child {
  margin-bottom: 10px;
}
</style>
