import Cookies from 'js-cookie';
import { SITE_INFO, AUTH_COOKIE, STORAGE_ITEM, LANG_COOKIE } from '@/libs/constants';

import { addHours } from 'date-fns';

export function setAuthToken(data, expires) {
  const authToken = data.token;
  const expiresDate = expires || addHours(new Date(), process.env.VUE_APP_LOGIN_TIME ? Number(process.env.VUE_APP_LOGIN_TIME) / 60 : 12);
  Cookies.set(AUTH_COOKIE.name, authToken, {
    expires: expiresDate,
    path: AUTH_COOKIE.path
  });
  localStorage.setItem(STORAGE_ITEM.time, expiresDate);
}

export function setCookieExtend(expires) {
  const authToken = getAuthToken();
  const expiresDate = addHours(new Date(), process.env.VUE_APP_LOGIN_TIME ? Number(process.env.VUE_APP_LOGIN_TIME) / 60 : 12);
  Cookies.set(AUTH_COOKIE.name, authToken, {
    expires: expires || expiresDate,
    path: AUTH_COOKIE.path
  });
  localStorage.setItem(STORAGE_ITEM.time, expiresDate);
}

export function setSiteData(data) {
  const siteInfo = JSON.stringify(data.site);
  // const siteInfo = JSON.stringify(testJson);
  Cookies.set(SITE_INFO.name, siteInfo, {
    expires: SITE_INFO.expires,
    path: SITE_INFO.path
  });
}

function removeAuthToken() {
  Cookies.remove(AUTH_COOKIE.name, {
    path: AUTH_COOKIE.path
  });
}
function removeSiteData() {
  Cookies.remove(SITE_INFO.name, {
    path: SITE_INFO.path,
    domain: SITE_INFO.domain
  });
}
export function setLocale() {
  // i18n 쿠키 확인
  if (!Cookies.get('locale')) {
    // 기본 언어 설정
    Cookies.set('locale', 'kr');
  }
}
export function getAuthToken() {
  return Cookies.get(AUTH_COOKIE.name, {
    path: AUTH_COOKIE.path
  });
}

export function getSiteData() {
  return Cookies.get(SITE_INFO.name, {
    path: SITE_INFO.path,
    domain: SITE_INFO.domain
  });
}

export function setAuthData(data) {
  // console.error('setData')
  setAuthToken(data);
  setSiteData(data);
}

export function removeAuthData() {
  removeAuthToken();
  removeSiteData();
  localStorage.clear();
}

export function getSiteInfo() {
  const siteInfoCookie = getSiteData();
  if (siteInfoCookie) {
    const siteInfo = JSON.parse(siteInfoCookie);
    return siteInfo;
  }
}

export const authBundle = {
  install() {
    return {
      getAuthToken,
      getSiteData,
      getSiteInfo
    };
  }
};
export function setLangCookie(value = 1) {
  document.body.classList = value;
  Cookies.set(LANG_COOKIE.name, value, {
    path: LANG_COOKIE.path,
    domain: LANG_COOKIE.domain
  });
}

export function getLangCookie() {
  return Cookies.get(LANG_COOKIE.name, {
    path: LANG_COOKIE.path,
    domain: LANG_COOKIE.domain
  });
}
