export default {
  name: 'reportsPartner',
  path: '/reportsPartner',
  component: () => import('@/views/member/reportsPartner'),
  props: true,
  meta: {
    menuMember: true,
    i18n: 'tabList.tjsd',
    text: '총판정산내역',
    code: 'LV080000'
  },
  redirect: {
    name: 'alltotalList'
  },
  children: [
    {
      name: 'alltotalList',
      path: '/reports/alltotalList',
      component: () => import('@/views/member/reportsPartner/pages/alltotalList'),
      meta: {
        tabMenu: true,
        i18n: 'tabList.tjsd',
        text: '총판정산내역',
        code: 'LV080800'
      }
    },
    {
      name: 'usertotalList',
      path: '/reports/usertotalList',
      component: () => import('@/views/member/reportsPartner/pages/usertotalList'),
      meta: {
        tabMenu: true,
        i18n: 'tabList.msd',
        text: '회원정산내역',
        code: 'LV080900'
      }
    }
  ]
};
