export const bet = {
  name: 'betGame',
  path: '/bet/',
  component: () => import('@/views/member/bet'),
  redirect: {
    name: 'betGameCasino'
  },
  meta: {
    layout: 'default',
    isActive: false,
    i18n: 'nav.manageBetting',
    text: '배팅 관리',
    icon: 'fas fa-dice',
    code: 'LV020000'
  },
  children: [
    {
      name: 'betGameCasino',
      path: '/bet/casino/list/all',
      component: () => import('@/views/member/bet/pages/casino/list'),
      meta: {
        menuMember: true,
        i18n: 'tabList.casino',
        text: '카지노',
        isActive: false,
        group: 'casino',
        code: 'LV020100'
      }
    },
    {
      name: 'betGameSlot',
      path: '/bet/slot/list/all',
      component: () => import('@/views/member/bet/pages/slot/list'),
      meta: {
        menuMember: true,
        i18n: 'tabList.slot',
        text: '슬롯',
        isActive: false,
        group: 'slot',
        code: 'LV020200'
      }
    },
    {
      name: 'betGameSport',
      path: '/bet/sports/list/all',
      component: () => import('@/views/member/bet/pages/sports/list'),
      meta: {
        menuMember: true,
        i18n: 'tabList.sports',
        text: '스포츠',
        isActive: false,
        group: 'sports',
        code: 'LV020300'
      }
    },
    {
      name: 'betGameMiniGame',
      path: '/bet/minigame/list/all',
      component: () => import('@/views/member/bet/pages/minigame/list'),
      meta: {
        menuMember: true,
        i18n: 'tabList.minigame',
        text: '미니게임',
        isActive: false,
        group: 'minigame',
        code: 'LV020400'
      }
    },
    {
      name: 'RestrictedWinnings',
      path: '/bet/restrict-winnings',
      component: () => import('@/views/member/bet/pages/RestrictedWinnings'),
      meta: {
        menuMember: true,
        i18n: 'tabList.RestrictWinningList',
        text: '당첨제한베팅내역',
        isActive: false,
        group: 'casino',
        code: 'LV020100'
      }
    },
    {
      name: 'AbnormalBetList',
      path: '/bet/abnormal/list',
      component: () => import('@/views/member/bet/pages/AbnormalProcessedList'),
      meta: {
        menuMember: true,
        i18n: 'tabList.AbnormalList',
        text: '미처리베팅목록',
        isActive: false,
        code: 'LV020400'
      }
    },
    {
      name: 'MinigameManageResult',
      path: '/bet/minigame/manage-result',
      component: () => import('@/views/member/bet/pages/MinigameManageResult'),
      meta: {
        menuMember: true,
        i18n: 'tabList.MinigameManageResult',
        text: '미니게임 결과 관리',
        isActive: false,
        group: 'minigame',
        code: 'LV020400'
      }
    }
  ]
};

// casino
//
// export const casino = {
//   name: "betGameCasino",
//   path: "/bet/casino",
//   component: () => import("@/views/member/bet"),
//   redirect: {
//     name: "CasinoSettings"
//   },
//   meta: {
//     group:"casino",
//     layout: "default",
//     isActive: false,
//     text: "카지노 관리",
//     icon: "fas fa-dice"
//   },
//   children: [
//     {
//       name: "CasinoSettings",
//       path: "/bet/casino/settings",
//       component: () => import("@/views/member/bet/pages/casino/settings"),
//       meta: {
//         menuMember: true,
//         text: "카지노게임 설정관리"
//       }
//     },
//     {
//       name: "casinoBetList",
//       path: "/bet/casino/list/:id",
//       component: () => import("@/views/member/bet/pages/casino/list"),
//       meta: {
//         menuMember: true
//       }
//     }
//   ]
// };
//
// //slot
// export const slot = {
//   name: "betGameSlot",
//   path: "/bet/slot",
//   component: () => import("@/views/member/bet"),
//   redirect: {
//     name: "SlotSettings"
//   },
//   meta: {
//     group:"slot",
//     layout: "default",
//     isActive: false,
//     text: "슬롯 관리",
//     icon: "fas fa-leaf"
//   },
//   children: [
//     {
//       name: "SlotSettings",
//       path: "/bet/slot/settings",
//       component: () => import("@/views/member/bet/pages/slot/settings"),
//       meta: {
//         menuMember: true,
//         text: "슬롯게임 설정관리"
//       }
//     },
//     {
//       name: "slotBetList",
//       path: "/bet/slot/list/:id",
//       component: () => import("@/views/member/bet/pages/slot/list"),
//       meta: {
//         menuMember: true
//       }
//     }
//   ]
// };

// slot
// export const minigame = {
//   name: "betMini",
//   path: "/bet/minigame",
//   component: () => import("@/views/member/bet"),
//   redirect: {
//     name: "miniGameSettings"
//   },
//   meta: {
//     layout: "default",
//     isActive: false,
//     text: "미니게임 관리",
//     icon: "fas fa-gamepad"
//   },
//   children: [
//     {
//       name: "mgSettings",
//       path: "/bet/minigame/settings",
//       component: () => import("@/views/member/bet/pages/minigame/settings"),
//       meta: {
//         menuMember: true,
//         text: "미니게임 설정관리"
//       }
//     },
//     {
//       name: "mgBetRate",
//       path: "/bet/minigame/betRate",
//       component: () => import("@/views/member/bet/pages/minigame/betRate"),
//       meta: {
//         menuMember: true,
//         text: "배당 설정"
//       }
//     },
//     {
//       name: "mgBetList",
//       path: "/bet/minigame/betList",
//       component: () => import("@/views/member/bet/pages/minigame/list"),
//       meta: {
//         menuMember: true,
//         text: "베팅내역"
//       },
//       props:true
//     },
//     {
//       name: "mgTotalBet",
//       path: "/bet/minigame/betReports/:gameCode",
//       component: () => import("@/views/member/bet/pages/minigame/reports"),
//       meta: {
//         menuMember: true,
//         text: "총 베팅관리"
//       }
//     }
//   ]
// };
// for (const gameType in GAME_INFO_LIST.minigame) {
//   const child = {
//     name: `${GAME_INFO_LIST.minigame[gameType.toString()]}BetList`,
//     path: `/bet/minigame/${gameType}`,
//     component: () => import("@/views/member/bet/pages/minigame/list"),
//     meta: {
//       menuMember: true,
//       transCode: `gameList.${GAME_INFO_LIST.minigame[gameType.toString()]}`
//     }
//   };
//   minigame.children.push(child)
// }
