export default {
  name: 'admin',
  path: '/admin',
  component: () => import('@/views/member/admin'),
  redirect: {
    name: 'AdminList'
  },
  meta: {
    layout: 'default',
    isActive: false,
    i18n: 'nav.manageAdmin',
    text: '관리자 환경설정',
    icon: 'fas fa-users-cog',
    code: 'LV110000'
  },
  children: [
    {
      name: 'AdminList',
      path: '/admin/list',
      component: () => import('@/views/member/admin/pages/list'),
      meta: {
        menuMember: true,
        i18n: 'tabList.adminList',
        text: '관리자 리스트',
        done: true,
        code: 'LV110100'
      }
    },
    // TODO
    // {
    //   name: 'AdminBlackList',
    //   path: '/admin/blackList',
    //   component: () => import('@/views/member/admin/pages/blackList'),
    //   meta: {
    //     menuMember: true,
    //     text: '블랙리스트 관리'
    //   }
    // },
    // {
    //   name: 'AdminPermissionGroup',
    //   path: '/admin/permissionGroup',
    //   component: () => import('@/views/member/admin/pages/permissionGroup'),
    //   meta: {
    //     menuMember: true,
    //     text: '관리자 권한 그룹'
    //   }
    // },
    {
      name: 'AdminWhiteListIP',
      path: '/admin/whiteList_ip',
      component: () => import('@/views/member/admin/pages/whiteList_ip'),
      meta: {
        menuMember: true,
        i18n: 'tabList.whiteList',
        text: '관리자 접속 아이피',
        code: 'LV110200'
      }
    }
  ]
};
