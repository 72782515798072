<template>
  <div class="ui-check-wrap">
    <input type='checkbox' :checked="checked" :value="value" :id="id" :name="name" @change="onChange" />
    <label :for='id'>
      <i class="fas fa-check"></i>
      <span v-if="text">{{text}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'UiCheck',
  props: ['text', 'value', 'name', 'id', 'checked'],
  methods: {
    onChange (event) {
      const convertChecked = event.target.checked ? 'Y' : 'N'
      this.$emit('setYn', convertChecked, this.name)
    }
  }
}
</script>

<style scoped>
.ui-check-wrap{
  font-size:1em;
  display: flex;
  justify-content: center;
  margin-right:10px;
}
.ui-check-wrap:last-child{
  margin-right:0;
}
input{
  display:none;
}

label{
  display: flex;
  align-items: center;
  margin-right: 10px;
}
label:last-child{
  margin-right: 0;
}
i{
    border: 2px solid #bfbfbf;
    background: #fff;
    border-radius: 5px;
    padding: 2px;
    font-size: 10px;
    color: #fff;
    margin-right: 4px;
}
input[type="checkbox"]:checked + label i {
  background: #18698b;
  border: 2px solid #353535;
}
label > *:last-child{
  margin:0;
}
</style>
