export default {
  name: 'Partner',
  path: '/partner',
  component: () => import('@/views/member/partner'),
  meta: {
    menuMember: true,
    i18n: 'tabList.partnerList',
    text: '파트너 목록',
    done: true,
    code: 'LV060100'
  },
  redirect: {
    name: 'partnerList'
  },
  children: [
    // {
    //   name: "partnerCreate",
    //   path: "/partner/partnerCreate",
    //   component: () => import("@/views/member/partner/pages/partnerCreate"),
    //   meta: {
    //     tabMenu: true,
    //     text: "파트너 생성",
    //     code: "LV060600"
    //   }
    // },
    {
      name: 'partnerList',
      path: '/partner/list',
      component: () => import('@/views/member/partner/pages/list'),
      meta: {
        menuMember: true,
        i18n: 'tabList.partnerList',
        text: '파트너 목록',
        done: true,
        code: 'LV060100'
      }
    },
    // {
    //   name: "partnerDetail",
    //   path: "/partner/partnerDetail",
    //   component: () => import("@/views/member/partner/pages/partnerdetail.vue"),
    //   meta: {
    //     tabMenu: true,
    //     text: "파트너 상세",
    //     code: "LV060700"
    //   }
    //  },
    // {
    //   name: 'PartnerTermReport',
    //   path: '/partner/reportTerm',
    //   component: () => import('@/views/member/partner/pages/report_term'),
    //   meta: {
    //     tabMenu: true,
    //     text: '파트너 기간별 정산내역'
    //   }
    // },
    {
      name: 'PartnerDetailReport',
      path: '/partner/reportDetail',
      component: () => import('@/views/member/partner/pages/report_detail'),
      meta: {
        tabMenu: true,
        text: '파트너 정산 상세내역',
        code: 'LV060200'
      }
    },
    {
      name: 'PartnerSetting',
      path: '/partner/setting',
      component: () => import('@/views/member/partner/pages/setting'),
      meta: {
        tabMenu: true,
        text: '파트너 설정',
        code: 'LV060300'
      }
    },
    // {
    //   name: 'PartnerSummary',
    //   path: '/partner/summary',
    //   component: () => import('@/views/member/partner/pages/summary'),
    //   meta: {
    //     tabMenu: true,
    //     text: '파트너 포인트 요약',
    //     code: "LV060400"
    //   }
    // },
    {
      name: 'PartnerPayout',
      path: '/partner/payout',
      component: () => import('@/views/member/partner/pages/payout'),
      meta: {
        tabMenu: true,
        text: '정산지급',
        code: 'LV060500'
      }
    }
  ]
};
