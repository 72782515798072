<template>
  <template v-if="layoutName">
    <default-layout />
  </template>
  <template v-else>
    <router-view :key="$route.fullPath" />
  </template>
</template>

<script>
import { getToken, headerCashInfo, headerTopInfo, headerBottomInfo } from './api/member';
import { getAuthToken, removeAuthData, setAuthToken, getSiteData } from '@/libs/auth-helper';

import DefaultLayout from './layouts/DefaultLayout.vue';

import { mapMutations } from 'vuex';
import Stomp from 'webstomp-client';
import SockJs from 'sockjs-client';

import { decrypt } from '@/libs/crypto';
import { SOCKET_SUBSCRIBE_DESTINATION } from '@/libs/constants';
import store from '@/store';

export default {
  name: 'App',
  components: {
    'default-layout': DefaultLayout
  },
  data() {
    return {
      hasError: false,
      lang: {},
      stompClient: null
    };
  },
  computed: {
    layoutName() {
      if (!this.$route.matched.length) {
        return null;
      }
      const route = this.$route;
      // // console.log(route)
      const menuMem = route.meta.menuMember;
      const tabMenu = route.meta.tabMenu;
      let useLayout = true;
      if (menuMem || tabMenu) {
        useLayout = true;
      } else {
        useLayout = false;
      }

      return useLayout;
    }
  },
  watch: {
    $route: {
      async handler(current) {
        if (current.name !== null) {
          const authToken = getAuthToken();
          if (!authToken) {
            this.signOut();
          } else {
            // this.socketConnect();
            await this.getCheckToken(true);
          }
        }
      },
      immediate: true
    }
  },
  async mounted() {
    window.CMS_TOKEN_CHECK = setInterval(async () => {
      await this.getCheckToken();
      const cookie = getAuthToken();
      if (cookie) {
        const res = await headerCashInfo();
        if (res.resultCode === '200') {
          store.commit('setAlarmInfo', res.data.headCashInfo);
          store.commit('setAlarmList', res.data.alarmList);
          store.commit('setLoginMember', res.data.loginMember);
          store.commit('setCreditBalance', res.data.creditBalance);
        }
      }
    }, 2000);

    window.CMS_HEDER_TOP_INFO = setInterval(async () => {
      await this.getCheckToken();
      const cookie = getAuthToken();
      if (cookie) {
        const res = await headerTopInfo();
        if (res.resultCode === '200') {
          // console.log(res.data.headerTopInfo)
          store.commit('setHeaderTopInfo', res.data.headerTopInfo);
          // console.log(res.data.underSiteInfo);
          store.commit('setUnderSiteList', res.data.underSiteInfo);
          store.commit('setCreditBalance', res.data.creditBalance);
        }
      }
    }, 1000 * 30);

    window.CMS_HEDER_BOTTOM_INFO = setInterval(async () => {
      await this.getCheckToken();
      const cookie = getAuthToken();
      if (cookie) {
        const res = await headerBottomInfo();
        if (res.resultCode === '200') {
          // console.log(res.data.headerBottomInfo)
          store.commit('setHeaderBottomInfo', res.data.headerBottomInfo);
        }
      }
    }, 1000 * 30);
  },
  methods: {
    async getCheckToken(force = false) {
      const cookie = getAuthToken();
      // const keyExpireDateStr = localStorage.getItem(STORAGE_ITEM.time)
      // const keyExpireDate = new Date(keyExpireDateStr)
      if (cookie) {
        const base64Payload = cookie.split('.')[1];
        const base64 = base64Payload.replace(/-/g, '+').replace(/_/g, '/');
        let payload = atob(base64).split('');
        // console.log(typeof payload)
        payload = payload
          .map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('');

        payload = decodeURIComponent(payload);

        const result = JSON.parse(payload);
        const expires = new Date(Number(result.exp + '000'));
        const now = new Date();

        const time = expires - now;

        const timeConvert = Math.floor((time / (1000 * 60)) % 60);
        // const timeConvert = 5

        if (force || timeConvert <= 5) {
          const res = await getToken();
          const resultCode = res.resultCode;
          // const resultCode = "9999";
          if (resultCode !== '200') {
            this.signOut();
          } else {
            setAuthToken(res.data);
            this.socketDisconnect();
            // this.socketConnect();
          }
        } else if (timeConvert < 0) {
          this.signOut();
        }
      } else {
        this.signOut();
      }
    },
    socketDisconnect() {
      if (this.stompClient) {
        if (this.stompClient.client) {
        }
      }
      this.stompClient = null;
    },
    socketConnect(type = 'adminfo', chatId = '1') {
      console.log('connenction');
      const token = getAuthToken();
      if (!token) {
        if (this.stompClient) {
          this.socketDisconnect();
        }
      }
      if (token && !this.stompClient) {
        this.stompClient = {
          client: null,
          token,
          type,
          chatId
        };
        const serverURL = process.env.VUE_APP_WEB_SOCKET_URL;
        const socket = new SockJs(serverURL);
        this.stompClient.client = Stomp.over(socket, { debug: false });
        this.stompClient.client.connect(
          {},
          frame => {
            // 소켓 연결 성공
            // 서버의 메시지 전송 endpoint를 구독합니다.
            // 이런형태를 pub sub 구조라고 합니다.
            const currentToken = getAuthToken();
            this.stompClient.client.subscribe(SOCKET_SUBSCRIBE_DESTINATION + type + '/' + currentToken, res => {
              this.getCheckToken();
              const jsonData = decrypt(res.body);
              const content = jsonData.content || null;
              if (content) {
                const msg = JSON.parse(jsonData.content);
                store.commit('setAlarmInfo', msg);
              }
            });
          },
          error => {
            // 소켓 연결 실패
            console.error(error);
            this.socketDisconnect();
            // this.socketConnect();
          }
        );
      }
    },
    ...mapMutations(['setErrorData', 'clearAllSiteData']),
    signOut() {
      this.clearAllSiteData();
      removeAuthData();
      this.$router.push({ name: 'login' });
    },
    async getMenuData() {
      await store.dispatch('storeMenuDate');
    },
    async setHeadCashInfo() {
      const res = await headerCashInfo();
      if (res.resultCode === '200') {
        store.commit('setAlarmInfo', res.data.headCashInfo);
      }
    },
    async setHeaderTopInfo() {
      const res2 = await headerTopInfo();
      if (res2.resultCode === '200') {
        // todo: headTopInfo 뭐임
        // console.log(res2.data)
        store.commit('setHeaderTopInfo', res2.data.headerTopInfo);
        console.log(res2.data);
        store.commit('setUnderSiteList', res2.data.underSiteInfo);
      }
    },
    async setHeaderBottomInfo() {
      const res3 = await headerBottomInfo();
      if (res3.resultCode === '200') {
        store.commit('setHeaderBottomInfo', res3.data.headerBottomInfo);
      }
    }
  },
  async created() {
    console.log('app created');
    const token = getAuthToken();
    if (token) {
      await Promise.all([this.getMenuData(), this.setHeadCashInfo(), store.dispatch('siteInitialize'), this.setHeaderTopInfo(), this.setHeaderBottomInfo()]);
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    console.log(from);
    const token = getAuthToken();
    const siteInfo = getSiteData();
    // console.log(token)
    if (token && siteInfo) {
      next();
    } else {
      removeAuthData();
      next('/login');
    }
  }
};
</script>

<style scoped>
/* .loginBody {
  background: #eee;
  height:100vh;
  min-width: 0;
} */
</style>
