<template>
  <main>
    <router-view :key="pageKey" />
  </main>
</template>

<script>
export default {
  name: 'InMain',
  props: ['currentMenu'],
  computed: {
    pageKey() {
      return this.$route.fullPath + new Date().getTime();
    }
  }
};
</script>

<style scoped>
main {
  width: 100%;
  height: calc(100% - 112px);
  box-sizing: border-box;
}
</style>
