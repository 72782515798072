import { createI18n } from 'vue-i18n';
import { getLangCookie, setLangCookie } from '@/libs/auth-helper';

import i18nMessages from './data/locale.json';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
// eslint-disable-next-line space-before-function-paren
function traverseItem(data, container, locale, fallbackLocale) {
  for (const key of Object.keys(data)) {
    const value = data[key];

    if (typeof value !== 'object') {
      continue;
    }

    if (typeof value[locale] === 'string') {
      container[key] = value[locale];
      continue;
    } else if (value[locale] === undefined && typeof value[fallbackLocale] === 'string') {
      container[key] = value[fallbackLocale];
      continue;
    }

    if (!container[key]) {
      container[key] = {};
    }
    traverseItem(value, container[key], locale, fallbackLocale);
  }
}

export function getLocaleMessages(locale, fallbackLocale) {
  const messages = {};
  traverseItem(i18nMessages, messages, locale, fallbackLocale);
  // console.log(messages)
  return messages;
}

let lang = getLangCookie();

if (!lang) {
  lang = 'ko';
  setLangCookie(lang);
}
document.body.classList = lang;

export default createI18n({
  locale: lang || 'ko',
  fallbackLocale: lang || 'ko',
  messages: {
    ko: getLocaleMessages('ko', 'ko'),
    en: getLocaleMessages('en', 'en'),
    cn: getLocaleMessages('cn', 'cn'),
    th: getLocaleMessages('th', 'th'),
    jp: getLocaleMessages('jp', 'jp'),
    vn: getLocaleMessages('vn', 'vn')
  }
});
