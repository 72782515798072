<template>
  <section id="modal-layer" >
    <!-- <component :is=""></component> -->
  </section>
</template>

<script>
export default {
  name: 'Modal',
  data() {
    return {
      props: {}
    }
  },
  computed: {
    modalState() {
      return this.$store.getters.modal
    }
  },
  watch: {
    modalState: {
      handler(value) {
        console.log(value)
      }
    }
  },
  created() {
    const modalData = {
      isActive: true,
      components: ''
    }
    this.$store.dispatch('setModal', modalData)
  }
}
</script>

<style scoped>
#modal-layer {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  /* display: flex; */
  display:none;
  justify-content: center;
  align-items: center;
}
</style>
