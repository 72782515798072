import { getCode, getGameCodes, memberDetail, comBank } from '@/api/member';
import { RATE_INCREASE } from '@/libs/constants';
import { getSiteData } from '@/libs/auth-helper';
import { getDateStr, replaceDateT, numberWithCommas, jsonSort, deleteCommas, onlyNumber } from '@/libs/utils';
import { decrypt, encrypt } from '@/libs/crypto';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      userData: {
        memPass: '',
        combinationMonoBetYn: '',
        companyLimit: '0',
        userLimit: '0',
        updId: '',
        domain: '',
        totalCashInAmt: '',
        subMemCnt: '0',
        subCompanyCnt: '0',
        memName: '',
        lastIp: '',
        cashAmt: '0',
        cashInPointYn: '',
        memDelYn: '',
        siteName: '',
        fstCashInPoinYn: '',
        specialBetYn: '',
        subMemRateModifyYn: '',
        pointAmt: '0',
        totalLoginCount: '',
        recommenderId: '',
        subMemBetLockYn: '',
        memberCommissionRate: [],
        memStatus: '',
        pointExchangeYn: '',
        maxCredit: '',
        lastLoginDt: '',
        combinationMultiBetYn: '',
        memPhone: '',
        adminId: '',
        totalCashOutAmt: '',
        outAmtYn: '',
        membetType: '',
        retailType: '',
        kplayId: '',
        specifiMemBetYn: '',
        replyPointYn: '',
        accInfoRequestYn: '',
        creditStatus: '',
        creditAmt: '0',
        regIp: '',
        totalWlAmt: '',
        redDt: '',
        retailPeriod: '',
        totalBonusAmt: '',
        siteId: '',
        regId: '',
        boardPointYn: '',
        partnerType: '',
        memLevel: '',
        updDt: '',
        memId: '',
        attendPointYn: '',
        totalMemCnt: '0'
      },
      memberFlow: [],
      partnerFlow: [],
      redData: {},
      pageInfo: {
        page: 1,
        count_per_list: 30,
        tatal_list_count: 0
      },
      gameListDefault: [],
      rateModel: {
        gameType: '',
        pointRate: '0.00',
        loseRate: '0.00',
        maxPointRate: '5.00',
        useYn: 'Y',
        betYn: 'N'
      },

      interval_1: {},
      interval_2: {},
      interval_3: {},
      rateIncrease: RATE_INCREASE
    };
  },
  computed: {
    ...mapState(['headCashInfo', 'underSiteList']),
    _siteInfo() {
      return this.$store.state.siteData;
    }
  },
  methods: {
    ...mapGetters(['siteData']),
    pageMove(name, params) {
      this.$router.push({ name, params });
    },
    decrypt() {
      return decrypt();
    },
    encrypt() {
      return encrypt();
    },
    setPageInfo(pageInfo) {
      if (pageInfo) {
        const limit = pageInfo.limit;
        const listCnt = pageInfo.tatal_list_count;
        const totalPage = Math.ceil(Number(listCnt) / Number(limit));
        pageInfo.tatal_page_count = totalPage;
        return pageInfo;
      } else {
        console.error('No pageInfo');
      }
    },
    async getBank() {
      // console.log(this.siteInfo);
      const req = {
        siteId: this.siteInfo.siteId
      };
      const res = await comBank(req);
      this.bankList = res.data.list;
    },
    getSiteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        // console.log(siteInfo);
        return siteInfo;
      }
    },
    async getMaxRate(memId) {
      const req = {
        memId
      };
      const res = await memberDetail(req);
      const rateList = [];
      try {
        if (res.resultCode === '200') {
          const rateInfo = res.data.member.memberCommissionRate;
          if (!rateInfo) {
            throw new Error('Rate Info is null');
          } else {
            for (const item of rateInfo) {
              if (item.useYn === 'Y') {
                const rateModel = {
                  gameType: item.gameType,
                  maxRate: item.pointRate ? item.pointRate : '0.00'
                };
                rateList.push(rateModel);
              }
            }
            return rateList;
          }
        } else {
          throw new Error('9999 fail');
        }
      } catch (e) {
        console.error(e);
        return rateList;
      }
    },
    resetRateToggle(currentRate, originRate, group) {
      if (group === '') {
        currentRate.forEach((target, index) => {
          target.betYn = originRate[index].betYn;
        });
      } else {
        currentRate.forEach((target, index) => {
          if (group === target.groupCode) {
            target.betYn = originRate[index].betYn;
          }
        });
      }
    },
    rateAllOn(rateArray, group, groupCode) {
      // console.log(rateArray)
      if (group === '') {
        rateArray.forEach(target => {
          if (!groupCode || target.groupCode === groupCode) {
            target.betYn = true;
          }
        });
      } else {
        rateArray.forEach(target => {
          if (target.groupCode === group) {
            target.betYn = true;
          }
        });
      }
    },
    rateAllOff(rateArray, group, groupCode) {
      if (group === '') {
        rateArray.forEach(target => {
          if (!groupCode || target.groupCode === groupCode) {
            target.betYn = false;
          }
        });
      } else {
        rateArray.forEach(target => {
          if (target.groupCode === group) {
            target.betYn = false;
          }
        });
      }
    },
    resetRate(currentRate, originRate, group) {
      if (group === '') {
        currentRate.forEach((target, index) => {
          target.pointRate = originRate[index].pointRate;
        });
      } else {
        currentRate.forEach((target, index) => {
          if (group === target.groupCode) {
            target.pointRate = originRate[index].pointRate;
          }
        });
      }
    },
    rateAllMin(rateArray, group, recommenderId) {
      if (recommenderId === '') {
        alert('상위회원을 선택해주세요.');
        return false;
      }
      // // console.log(group)
      if (group === '') {
        rateArray.forEach(target => {
          target.pointRate = target.minPointRate;
        });
      } else {
        rateArray.forEach(target => {
          if (target.groupCode === group) {
            target.pointRate = target.minPointRate;
          }
        });
      }
    },
    rateAllMax(rateArray, group, recommenderId) {
      console.log(rateArray);
      console.log('kkkkkk');
      if (recommenderId === '') {
        alert('상위회원을 선택해주세요.');
        return false;
      }
      console.log(group);
      if (group === '') {
        rateArray.forEach(target => {
          target.pointRate = target.maxPointRate;
        });
      } else {
        rateArray.forEach(target => {
          if (target.groupCode === group) {
            target.pointRate = target.maxPointRate;
          }
        });
      }
    },
    rateLoseAllMax(rateArray, group, recommenderId) {
      if (recommenderId === '') {
        alert('상위회원을 선택해주세요.');
        return false;
      }

      if (group === '') {
        rateArray.forEach(target => {
          target.loseRate = target.maxLoseRate;
        });
      } else {
        rateArray.forEach(target => {
          if (target.groupCode === group) {
            target.loseRate = target.maxLoseRate;
          }
        });
      }
    },
    rateLoseAllMin(rateArray, group) {
      // // console.log(group)
      if (group === '') {
        rateArray.forEach(target => {
          target.loseRate = target.minLoseRate;
        });
      } else {
        rateArray.forEach(target => {
          if (target.groupCode === group) {
            target.loseRate = target.minLoseRate;
          }
        });
      }
    },
    detailOpen(type, siteId, targetId) {
      const name = type + 'Detail';
      const size = {
        width: 1210,
        height: 850
      };
      const position = {
        top: 0,
        left: 0
      };
      if (type === 'company') {
        size.height = 1100;
      }
      const routeData = this.$router.resolve({ name, query: { siteId: siteId, id: targetId } });
      const options = `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`;
      // window.open(routeData.href, '_blank')
      window.open(routeData.href, `Detail_${type}`, options);
    },
    onlyNumber(e) {
      return onlyNumber(e);
    },
    getDateStr(value, format) {
      return getDateStr(value, format);
    },
    numberWithCommas(value, fixed = 0) {
      return numberWithCommas(value, fixed);
    },
    deleteCommas(value) {
      return deleteCommas(value);
    },
    replaceDateT(value) {
      return replaceDateT(value);
    },
    jsonSort(item, ord, type) {
      return jsonSort(item, ord, type);
    },
    ynToBool(item) {
      let text = item;
      if (item === 'Y') {
        text = false;
      } else if (item === 'N') {
        text = true;
      } else {
        console.error('item type check: is not YN');
        text = '';
      }
      return text;
    },
    boolToYn(item) {
      let bool = item;
      if (typeof bool !== 'boolean') {
        console.error('item type check: is not bool');
        bool = null;
      }
      if (bool) {
        bool = 'Y';
      } else {
        bool = 'N';
      }

      return bool;
    },
    async getUserList() {},
    async setGameList() {
      // const deduplicate = arr => {
      //   arr.filter((item1, idx1) => {
      //     return (
      //       arr.findIndex((item2, idx2) => {
      //         return item1.code === item2.code
      //       }) === idx1
      //     )
      //   })
      // }
      // console.log(deduplicate)
      const groups = await getCode('gameGroup');
      const games = await getGameCodes('procId', '', '', this.getSiteInfo().siteId);
      // console.log(groups)
      // console.log(games)
      const _groups = [];
      if (games.length !== 0) {
        for (const group of groups) {
          // if (group.useYn === "Y") {
          group.child = [];
          for (let i = 0; i < games.length; i++) {
            if (group.code === games[i].groupCode) {
              // console.log(group.code === games[i].groupCode)
              if (games[i].groupCode === 'minigame') {
              }
              // if (games[i].useYn === "Y") {
              group.child.push(games[i]);
              // }
            }
          }
          _groups.push(group);
          // }
        }
      }

      this.$store.commit('setGameList', _groups);
      this.gameListDefault = _groups;
    }
  }
};
